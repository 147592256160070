@media only screen and (max-width: 576px) {
  .container10 {
    max-width: 100vw !important;
  }

  .navbar-nav {
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .nav-link.active {
    width: 144px !important;
  }

  .logo-img {
    width: 120px !important;
    height: 112px !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .container10 {
    max-width: 100vw !important;
  }

  .navbar-nav {
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .nav-link.active {
    width: 144px !important;
  }

  .logo-img {
    width: 130px !important;
    height: 122px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .container10 {
    max-width: 100vw !important;
  }

  .navbar-nav {
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .nav-link.active {
    width: 144px !important;
  }

  .logo-img {
    width: 140px !important;
    height: 132px !important;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1200px) {
  #cont-fld {
    gap: 0 !important;
  }

  .navbar-nav {
    gap: 10px !important;
  }
  .navbar-collapse {
    justify-content: space-between !important;
  }

  .logo-img {
    width: 120px !important;
    height: 120px !important;
  }
}
@media only screen and (max-width: 991px) {
  #cont-fld {
    gap: 0 !important;
  }

  .navbar-nav {
    gap: 10px !important;
  }
  .navbar-collapse {
    justify-content: space-between !important;

    margin-top: -3%;
    margin-bottom: 8%;
  }

  .logo-img {
    width: 120px !important;
    height: 120px !important;
  }
  .treat-move {
    margin-right: 1%;
  }
  .dropdown {
    margin-left: 1% !important;
  }
}
@media only screen and (min-width: 1500px) {
  .navbar-collapse {
    justify-content: space-around !important;
  }
}

@media only screen and (max-width: 1399px) {
  .container10 {
    width: 95%;
  }
}
@media only screen and (min-width: 992px) {
  .dropdown-menu {
    margin-top: 10% !important;
    margin-left: -35% !important;
  }
}
@media only screen and (max-width: 991px) {
  .dropdown-menu {
    margin-top: 5% !important;
  }
  .dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
