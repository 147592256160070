@import url("https://fonts.cdnfonts.com/css/mona-sans");
@import url("https://fonts.cdnfonts.com/css/kollektif");
@import url("https://fonts.cdnfonts.com/css/sf-pro-display");
.lusia-container-full-contained-offer {
  max-width: 1440px;
  width: 100%;
  display: flex;
  align-items: center;
  /* background-color: yellow; */
}
.lusia-conatiner-offer {
  width: 100%;
  height: auto;
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
  margin-bottom: 1%;
}
.lusia-left-offer {
  width: 55%;
  display: flex;
  align-items: end;
  /* justify-content: end; */
  flex-direction: column;
  /* background-color: green; */
}
.lusia-left-offer1 {
  width: 50%;
  display: flex;
  align-items: end;
  /* justify-content: end; */
  flex-direction: column;
  /* background-color: green; */
}
.lusia-right-offer {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.lusia-right-offer1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  /* background-color: yellow; */
  padding-bottom: 55px;
}
.lusia-contained-offer {
  width: 90%;
  /* background-color: yellow; */
  margin-bottom: 1.5%;
}
.res-bes {
  margin-right: 5%;
}

.expert-content10 {
  display: flex;
  width: 100%;
  /* background-color: green; */
}
.expert-img10 {
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* background-color: red; */
}
.expert-img10 img {
  object-fit: cover;
}
.expert-heading-control-offer {
  color: #d521a0;
  font-family: "SF Pro Display", sans-serif;
  font-size: 20px;
  margin-left: 1%;
  font-weight: 500;
}
.expert-content-oral {
  display: flex;
  width: 100%;
  /* background-color: green; */
}
.expert-img-oral {
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* background-color: red; */
}
.expert-img-oral img {
  object-fit: cover;
}
.expert-heading-control-offer-oral {
  color: #d521a0;
  font-family: "SF Pro Display", sans-serif;
  font-size: 20px;
  margin-left: 1%;
  font-weight: 500;
}
.expert-heading-control-offer-oral-enlight {
  color: #d521a0;
  font-family: "SF Pro Display", sans-serif;
  font-size: 20px;
  margin-left: 1%;
  font-weight: 500;
}
.lusia-para-offer {
  width: 100%;
  /* padding-top: 2%; */
  /* margin-right: 5%; */
}
.lusia-para-offer p {
  font-family: "SF Pro Display", sans-serif;
  font-size: 34px;
  line-height: 40px;
  font-weight: 500;
  letter-spacing: -1px;
}
.lusia-para-offer-oral {
  width: 100%;
  /* padding-top: 2%; */
  /* margin-right: 5%; */
}
.lusia-para-offer-oral p {
  font-family: "SF Pro Display", sans-serif !important;
  font-size: 22px;
  line-height: 30px;
  font-weight: 400 !important;
  letter-spacing: -1px;
}
.lusia-para-offer-oral-enlight {
  width: 100%;
  /* padding-top: 2%; */
  /* margin-right: 5%; */
}
.lusia-para-offer-oral-enlight p {
  font-family: "SF Pro Display", sans-serif !important;
  font-size: 22px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: -1px;
}
.lusia-right-contained-offer {
  width: 83.5%;
  /* background-color: yellow; */
}
.lusia-right-contained-offer1 {
  width: 92%;
  /* background-color: yellow; */
}
.right-para-offer {
  width: 100%;
  margin-top: 10%;
}

.right-para-offer p {
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}
