@import url("https://fonts.cdnfonts.com/css/mona-sans");
@import url("https://fonts.cdnfonts.com/css/kollektif");
@import url("https://fonts.cdnfonts.com/css/sf-pro-display");
.Contact {
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.containt-main-full {
  width: 1440px;
  /* width: 74%; */
  display: flex;
  justify-content: center;
}
.testi-contact-main {
  width: 90% !important;
  background-color: white !important;
}
.insta-img-set {
  width: 100%;
}
.insta-img-set img {
  object-fit: contain;
  width: 50px;
}
.set-image-set {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}
.set-image-set img {
  object-fit: contain;
  width: 95%;
  border-radius: 10px;
}
.Contact-main {
  max-width: 1440px;
  background-color: #fff7fc;
  /* background-color: green; */
  /* width: 1240px; */
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  /* min-height: 92vh; */
  border-radius: 10px;
  padding-top: 4%;
  padding-bottom: 4%;
}
.Contact-main10 {
  max-width: 1440px;
  background-color: #fff7fc;
  /* background-color: green; */
  /* width: 1240px; */
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  /* min-height: 92vh; */
  border-radius: 10px;
  /* padding-top: 4%;
  padding-bottom: 4%; */
  margin-top: -5%;
}

.Contact-main2 {
  max-width: 1440px;
  background-color: #fff7fc;
  /* background-color: green; */
  /* width: 1240px; */
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  /* min-height: 92vh; */
  border-radius: 10px;
  padding-top: 4%;
  padding-bottom: 4%;
}
.Contact-main1 {
  max-width: 1440px;
  background-color: #fff7fc;
  /* background-color: green; */
  /* width: 1240px; */
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  /* min-height: 92vh; */
  border-radius: 10px;
  padding-top: 4%;
  padding-bottom: 4%;
  margin-top: 4%;
}
.Contact-main-inside {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  /* width: 1083px; */
  width: 94%;
  gap: 8px;
  /* background-color: red; */
}
.Contact-left-side {
  display: flex;
  width: 48%;
  flex-direction: column;
  /* background-color: lightskyblue; */
  justify-content: center;
  /* height: 100vh; */
  margin-bottom: 60px;
}
.lusia-flus {
  margin-bottom: 30px;
}
.lusia-set-max {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.question-lusia {
  width: 90%;
  display: flex;
  justify-content: start;
  margin-top: 25px;
  margin-bottom: 20px;
}
.question-lusia p {
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  font-family: "SF Pro Display", sans-serif;
  color: #18181b;
  letter-spacing: -0.5px;
}
.Contact-left-side1 {
  display: flex;
  /* width: 100%; */
  /* width: 610px; */
  width: 48%;
  flex-direction: column;
  height: 650px;
  /* height: 100%; */
  /* background-color: green; */
}
.Contact-left-two-section {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  /* background-color: red; */
  /* padding-bottom: 20px; */
}
.Contact-left-section-content {
  display: flex;
  flex-direction: column;
  height: 400px;
  justify-content: space-between;
  align-items: flex-start;
}
.Contact-left-small-heading {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* background-color: lime; */
}
.Contact-left-small-heading1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 4.5%;
}
.Contact-left-small-heading .line {
  border-bottom: 2px solid #d521a0;
  width: 30px;
  margin-top: 10px;
  /* background-color: yellow; */
}
.Contact-left-small-heading1 .line {
  border-bottom: 2px solid #d521a0;
  width: 30px;
  margin-top: 10px;
  /* background-color: yellow; */
}
.Contact-left-small-heading .Left-smallpink-heading {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: #d521a0;
  font-size: 20px;
  font-weight: 400;
  line-height: 23.87px;
  font-family: "SF Pro Display", sans-serif;
  gap: 1%;
  width: 100%;
  margin-bottom: 20%;
  margin-top: -7%;
}
.Contact-left-small-heading1 .Left-smallpink-heading {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: #d521a0;
  font-size: 20px;
  font-weight: 400;
  line-height: 23.87px;
  font-family: "SF Pro Display", sans-serif;
  gap: 1%;
  width: 100%;
}
.left-section-data {
  display: flex;
  flex-direction: column;
}
.question p {
  font-size: 33px;
  line-height: 40.57px;
  font-weight: 400;
  font-family: "SF Pro Display", sans-serif;
  color: #18181b;
  letter-spacing: -0.5px;
  width: 95%;
  margin-bottom: 15%;
}
.question1 p {
  font-size: 33px;
  line-height: 40.57px;
  font-weight: 400;
  font-family: "SF Pro Display", sans-serif;
  color: #18181b;
  letter-spacing: -0.5px;
  width: 95%;
}
.question2 p {
  font-size: 33px;
  line-height: 40.57px;
  font-weight: 400;
  font-family: "SF Pro Display", sans-serif;
  color: #18181b;
  letter-spacing: -0.5px;
  width: 90%;
}
.left-section-data .smalldata {
  font-family: "SF Pro Display", sans-serif;
  color: #9f9f9f;
  font-size: 16px;
  width: 80%;
  margin-top: 5%;
}
.left-section-data .smalldata1 {
  font-family: "SF Pro Display", sans-serif;
  color: #9f9f9f;
  font-size: 16px;
  width: 80%;
  /* margin-top: 5%; */
}
.left-section-data .smalldata2 {
  font-family: "SF Pro Display", sans-serif;
  color: #9f9f9f;
  font-size: 16px;
  width: 75% !important;
  /* margin-top: 5%; */
  /* background-color: red; */
}
.contact-heading-container-contained-flex {
  width: 80%;
  /* background-color: yellow; */
}
.contact-heading-container-contained-flex p {
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
}
.contact-heading-container-contained-flex1 {
  width: 78%;
  /* background-color: yellow; */
}
.contact-heading-container-contained-flex1 p {
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
}
.heading-flex-contained {
  display: flex;
  justify-content: start;
  margin-top: 20%;
}
.Contact-left-section-smallimagebtn {
  /* background-color: red; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  /* background-color: red; */
  /* justify-content: space-evenly;
  flex-direction: column; */
  /* background-color: red; */
  /* padding-top: 2%; */
  /* padding-bottom: 4%; */
  /* margin-top: 5%; */
}
.Contact-left-section-smallimagebtn img {
  object-fit: cover;
}
.btn-container {
  width: 100%;
  margin-top: 20%;
}
.btn-container button {
  /* width: 133px; */
  height: 46px;
  border-radius: 30px;
  outline: none;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.btn-container10 {
  width: 100%;
}
.btn-container10 button {
  width: 133px;
  height: 46px;
  border-radius: 30px;
  outline: none;
  border: none;
  background-color: #343434;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.left-section-last-frame {
  width: 100%;
  /* background-color: red; */
}
.btn-container1 {
  width: 100%;
  /* background-color: red; */
}
.btn-container1 button {
  border: none;
  outline: none;
  width: 275px;
  height: 44px;
  border-radius: 30px;
}
.btn-container-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-left {
  width: 35%;
  /* background-color: pink; */
  display: flex;
  justify-content: space-evenly;
}
.btn-left img {
  object-fit: cover;
}
.left-heading-container {
  display: flex;
}

.email-heading {
  font-size: 16px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 400;
}
.line-img {
  object-fit: cover;
  margin-left: 1%;
}
.btn-right {
  width: 55%;
  /* background-color: orange; */
}
.right-btn-heading {
  font-family: "SF Pro Display", sans-serif;
  font-size: 17px;
  font-weight: 400;
}
.Contact-right-section {
  display: flex;
  justify-content: center;
  width: 52%;
  /* width: 433px; */
  flex-direction: column;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}
.Contact-right-section-heading {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 3%;
  margin-top: 1%;
}
.Contact-right-section h5 {
  font-family: "SF Pro Display", sans-serif;
  font-weight: 600;
}
.form-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-color: red; */
}
.flex-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 1px solid purple; */
}
.contact-right-label {
  width: 98%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4%;
  margin-bottom: 1%;
  margin-right: 1.5%;

  /* background-color: red; */
}
.treat-move:hover {
}
.contact-right-label label {
  font-size: 16px;
  line-height: 10px;
  font-weight: 400;
  color: #1b1b1b;
  font-family: "SF Pro Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 3%;
  /* background-color: red; */
}
.lable-setting {
  margin-top: -1%;
}

.contact-right-input {
  width: 100%;
}
.contact-right-input input {
  width: 100%;
  border-radius: 8px;
  height: 60px;
  border: 1px solid #b8b8b8;
  padding-left: 10px;
}
.input-image-flezzz input {
  padding-left: 10px !important;
}
.flex-item textarea {
  width: 100%;
  border: 1px solid #b8b8b8;
  height: 140px;
  border-radius: 8px;
  padding-left: 5%;
  padding-top: 1%;
}
.para-form2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 98%;
  margin-top: 2%;
}
.para-form2 p {
  font-size: 16px;
  line-height: 24px;
  color: #18181b;
  font-family: "Poppins";
  width: 95%;
  margin-left: 2%;
  /* background-color: red; */
}
.para-form-input {
  display: flex;
  align-items: flex-start;
}
.para-form-input input {
  border-color: #b8b8b8;
  width: 16px;
  margin-top: 2%;
}

.form-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-button button {
  height: 60px;
  width: 98%;
  border-radius: 8px;
  gap: 6px;
  border: 1px solid #d521a0;
  padding: 20px, 114.5px, 20px, 114.5px;
  color: #ffffff;
  font-size: 16px;
  line-height: 16px;
  background-color: #d521a0;
  font-family: "SF Pro Display", sans-serif;
}
.form-button button img {
  width: 20px;
  height: 20px;
}
.btn-container button img {
  object-fit: contain;
  width: 60px;
}
@media screen and (max-width: 1056px) {
  .Contact-right-section {
    width: 50%;
  }
}
@media screen and (max-width: 900px) {
  .Contact-main-inside {
    flex-direction: column;
  }
  .Contact-left-small-heading1 .line {
    margin-top: 0px;
  }
  .question p {
    margin-bottom: 0%;
  }
  .left-section-data .smalldata {
    margin-top: 0%;
  }
  .btn-container {
    margin-top: 0%;
  }
  .Contact-left-small-heading .Left-smallpink-heading {
    margin-bottom: 8%;
    margin-top: -5%;
  }

  .Contact-left-side {
    width: 100%;
    /* background-color: lime; */
    height: 100% !important;
  }
  .Contact-left-side1 {
    width: 100%;
    margin-bottom: 5%;
    margin-top: 5%;
  }
  .Contact-right-section {
    width: 100%;
  }
  .para-form2 {
    width: 100%;
  }
  .Contact-main {
    padding-bottom: 3%;
  }
  .Contact-main10 {
    padding-bottom: 3%;
  }
  .Contact-main2 {
    padding-bottom: 3%;
  }
  .Contact-main1 {
    padding-bottom: 3%;
  }
  .heading-flex-contained {
    margin-top: 0%;
  }
  .Contact-main2 {
    padding-top: 0%;
    margin-top: -3%;
  }
}
@media screen and (min-width: 685px) and (max-width: 900px) {
  .para-form-input {
    height: 100%;
    display: flex;
    align-items: center;
    margin-top: 0;
  }
  .left-section-data .smalldata {
    font-size: 16px;
    /* background-color: red; */
  }
  .left-section-data .smalldata1 {
    font-size: 16px;
    /* background-color: red; */
  }
  .left-section-data .smalldata2 {
    font-size: 16px;
    /* background-color: red; */
  }
  .para-form2 p {
    width: 100%;
  }
  .para-form-input {
    display: flex;
    align-items: flex-start;
  }
  .Contact-left-side {
    height: 360px;
    /* background-color: red; */
  }
  .Contact-left-side1 {
    height: 360px;
  }
  .Contact-left-section-content {
    height: 290px;
  }
}
@media screen and (max-width: 684px) {
  .Contact-left-side {
    height: 400px;
  }
  .Contact-left-side1 {
    height: 400px;
  }
  .Contact-left-section-content {
    height: 290px;
  }
}
@media screen and (max-width: 685px) {
  .para-form2 p {
    width: 100%;
  }
}

/* media queries for question in left section */
@media screen and (max-width: 1422px) {
  .question p {
    width: 97%;
  }
  .question1 p {
    width: 97%;
  }
  .question2 p {
    width: 97%;
  }
}
@media screen and (max-width: 1394px) {
  .question p {
    font-size: 33px;
  }
  .question1 p {
    font-size: 33px;
  }
  .question2 p {
    font-size: 33px;
  }
}
@media screen and (max-width: 1353px) {
  .question p {
    font-size: 32px;
  }
  .question1 p {
    font-size: 32px;
  }
  .question2 p {
    font-size: 32px;
  }
}
@media screen and (max-width: 1312px) {
  .question p {
    font-size: 31px;
  }
  .question1 p {
    font-size: 31px;
  }
  .question2 p {
    font-size: 31px;
  }
}

@media screen and (max-width: 1271px) {
  .question p {
    font-size: 30px;
  }
  .question1 p {
    font-size: 30px;
  }
  .question2 p {
    font-size: 30px;
  }
}
@media screen and (max-width: 1230px) {
  .question p {
    font-size: 29px;
  }
  .question1 p {
    font-size: 29px;
  }
  .question2 p {
    font-size: 29px;
  }
}

@media screen and (max-width: 1189px) {
  .question p {
    font-size: 28px;
  }
  .question1 p {
    font-size: 28px;
  }
  .question2 p {
    font-size: 28px;
  }
}

@media screen and (max-width: 1148px) {
  .question p {
    font-size: 27px;
  }
  .question1 p {
    font-size: 27px;
  }
  .question2 p {
    font-size: 27px;
  }
}

@media screen and (max-width: 1107px) {
  .question p {
    font-size: 26px;
  }
  .question1 p {
    font-size: 26px;
  }
  .question2 p {
    font-size: 26px;
  }
}

@media screen and (max-width: 1066px) {
  .question p {
    font-size: 25px;
  }
  .question1 p {
    font-size: 25px;
  }
  .question2 p {
    font-size: 25px;
  }
}
@media screen and (max-width: 1057px) {
  .question p {
    font-size: 23px;
  }
  .question1 p {
    font-size: 23px;
  }
  .question2 p {
    font-size: 23px;
  }
  .left-section-data .smalldata {
    font-size: 13px;
    width: 90%;
  }
  .left-section-data .smalldata1 {
    font-size: 13px;
    width: 90%;
  }
  .left-section-data .smalldata2 {
    font-size: 13px;
    width: 90%;
  }
}
@media screen and (max-width: 1042px) {
  .question p {
    font-size: 22px;
  }
  .question1 p {
    font-size: 22px;
  }
  .question2 p {
    font-size: 22px;
  }
}
@media screen and (max-width: 576px) {
  .question p {
    font-size: 20px;
    line-height: 40.57px;
  }
  .question1 p {
    font-size: 20px;
    line-height: 40.57px;
  }
  .question2 p {
    font-size: 20px;
    line-height: 40.57px;
  }
}
@media screen and (max-width: 523px) {
  .question p {
    font-size: 19px;
    line-height: 35px;
  }
  .question1 p {
    font-size: 19px;
    line-height: 35px;
  }
  .question2 p {
    font-size: 19px;
    line-height: 35px;
  }
}

/* media queries for left side content under question */

@media screen and (max-width: 1021px) {
  .left-section-data .smalldata {
    font-size: 13px;
    width: 93%;
  }
  .left-section-data .smalldata1 {
    font-size: 13px;
    width: 93%;
  }
  .left-section-data .smalldata2 {
    font-size: 13px;
    width: 93%;
  }
}
@media screen and (max-width: 1078px) {
  .left-section-data .smalldata {
    font-size: 14px;
  }
  .left-section-data .smalldata1 {
    font-size: 14px;
  }
  .left-section-data .smalldata2 {
    font-size: 14px;
  }
}
@media screen and (max-width: 1176px) {
  .left-section-data .smalldata {
    width: 89%;
    font-size: 15px;
  }
  .left-section-data .smalldata1 {
    width: 89%;
    font-size: 15px;
  }
  .left-section-data .smalldata2 {
    width: 89%;
    font-size: 15px;
  }
}
@media screen and (max-width: 1204px) {
  .left-section-data .smalldata {
    width: 87%;
  }
  .left-section-data .smalldata1 {
    width: 87%;
  }
  .left-section-data .smalldata2 {
    width: 87%;
  }
}
@media screen and (max-width: 1458px) {
  .left-section-data .smalldata {
    width: 85%;
  }
  .left-section-data .smalldata1 {
    width: 85%;
  }
  .left-section-data .smalldata2 {
    width: 85%;
  }
}
@media screen and (max-width: 576px) {
  .left-section-data .smalldata {
    font-size: 12px;
  }
  .left-section-data .smalldata1 {
    font-size: 12px;
  }
  .left-section-data .smalldata2 {
    font-size: 12px;
  }
}

/* media query for data to be centered after 600px */

@media screen and (max-width: 600px) {
  .Contact-left-side {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    text-align: center;
  }
  .lusia-flus {
    margin-left: 0;
  }
  .question-lusia {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    width: 100%;
    /* background-color: red; */
  }
  .lusia-flus {
    /* width: 100%; */
    /* background-color: red; */
  }

  .Contact-left-side1 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .Contact-left-two-section {
    display: flex;
    align-items: center;
  }
  .Contact-left-section-content {
    display: flex;
    align-items: center;
    text-align: center;
    /* justify-content: center; */
    /* background-color: red; */
  }
  .Contact-left-small-heading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Contact-left-small-heading .Left-smallpink-heading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Contact-left-small-heading .line {
    margin-top: 0;
  }
  .Contact-left-small-heading1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Contact-left-small-heading1 .Left-smallpink-heading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Contact-left-small-heading1 .line {
    margin-top: 0;
  }
  .left-section-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .question {
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .question1 {
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .question2 {
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .left-section-data .smalldata {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 83%;
    font-size: 14px;
    text-align: center;
  }
  .left-section-data .smalldata1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 83%;
    font-size: 14px;
    text-align: center;
  }
  .left-section-data .smalldata2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 83%;
    font-size: 14px;
    text-align: center;
  }
  .Contact-left-section-smallimagebtn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .left-section-last-frame {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Contact-right-section-heading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn-container {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 3%;
  }
  .btn-container10 {
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .Contact-left-side {
    height: 320px;
    /* background-color: red; */
  }
  .Contact-left-side1 {
    height: 320px;
    /* background-color: red; */
  }
  /* .Contact-left-section-content {
    height: 290px;
  } */
  .contact-heading-container-contained-flex {
    /* background-color: orange; */
    width: 90%;
  }
  .contact-heading-container-contained-flex p {
    line-height: 20px;
  }
  .contact-heading-container-contained-flex1 {
    /* background-color: orange; */
    width: 90%;
  }
  .contact-heading-container-contained-flex1 p {
    line-height: 20px;
  }
  .heading-flex-contained {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 490px) {
  .btn-container {
    margin-bottom: 5%;
  }
}
@media screen and (max-width: 347px) {
  .question p {
    line-height: 22px;
    font-size: 20px;
  }
  .question1 p {
    line-height: 22px;
    font-size: 20px;
  }
  .question2 p {
    line-height: 22px;
    font-size: 20px;
  }
}
@media screen and (max-width: 360px) {
  .btn-container1 button {
    width: 250px;
  }
  .right-btn-heading {
    font-size: 15px;
  }
}
.custom-checkbox {
  width: 17px;
  height: 17px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid rgba(128, 128, 128, 0.24);
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  position: relative;
}

.custom-checkbox:checked {
  background-color: #d521a0;
  border: 1px solid rgba(133, 133, 133, 0.267);
}

.custom-checkbox::before {
  content: "\2713"; /* Unicode checkmark character */
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white; /* Color of the checkmark */
  font-size: 12px; /* Adjust the font size as needed */
  visibility: hidden;
  /* background-color: #d521a0; */
}

.custom-checkbox:checked::before {
  visibility: visible;
}
.input-checked {
  margin-top: 1%;
}
.para-form2-content {
  margin-left: 2%;
  width: 100%;
  /* margin: 0; */
  /* background-color: red; */
}
.input-image-set {
  background: url("../../Assets-Dental/Frame\ \(1\).svg") no-repeat 13px center;
}
.input-image-set1 {
  background: url("../../Assets-Dental/message3.svg") no-repeat 15px center;
}
.input-image-set2 {
  background: url("../../Assets-Dental/contact.svg") no-repeat 15px center;
}
.input-image-set22 {
  background: url("../../Assets-Dental/Frame\ \(1\).svg") no-repeat 13px center;
}
.input-image-set23 {
  background: url("../../Assets-Dental/message3.svg") no-repeat 15px center;
}
.input-image-set24 {
  background: url("../../Assets-Dental/contact.svg") no-repeat 15px center;
}
@media screen and (min-width: 450px) and (max-width: 900px) {
  .para-form2-content {
    margin: 0;
    width: 100%;
    /* background-color: red; */
  }
}
@media screen and (min-width: 901px) {
  .Contact-left-side {
    margin-top: 5%;
  }
  .Contact-left-side1 {
    margin-top: 5%;
  }
}
@media screen and (max-width: 1300px) {
  .left-section-last-frame {
    padding-bottom: 1%;
  }
}
@media screen and (max-width: 1200px) {
  .left-section-last-frame {
    padding-bottom: 2%;
  }
}
@media screen and (max-width: 1100px) {
  .left-section-last-frame {
    padding-bottom: 3%;
  }
}
.input-image-set.has-content {
  background-image: none;
}
.input-image-set1.has-content {
  background-image: none;
}
.input-image-set2.has-content {
  background-image: none;
}
.input-image-set22.has-content {
  background-image: none;
}
.input-image-set23.has-content {
  background-image: none;
}
.input-image-set24.has-content {
  background-image: none;
}
@media screen and (min-width: 578px) and (max-width: 900px) {
  .input-checked {
    margin-top: 0%;
    padding-top: 2px;
  }
}
@media screen and (max-width: 1143px) {
  .contact-heading-container-contained-flex {
    margin-top: 5%;
  }
  .contact-heading-container-contained-flex p {
    font-size: 15px;
  }
  .contact-heading-container-contained-flex1 {
    margin-top: 5%;
  }
  .contact-heading-container-contained-flex1 p {
    font-size: 15px;
  }
}
@media screen and (max-width: 1071px) {
  .contact-heading-container-contained-flex {
    width: 100%;
  }
  .contact-heading-container-contained-flex1 {
    width: 100%;
  }
}
@media screen and (max-width: 1040px) {
  .contact-heading-container-contained-flex {
    width: 95%;
  }
  .contact-heading-container-contained-flex1 {
    width: 95%;
  }
}
@media screen and (max-width: 490px) {
  .question p {
    font-size: 18px;
    line-height: 27px;
  }
  .question1 p {
    font-size: 18px;
    line-height: 27px;
  }
  .question2 p {
    font-size: 18px;
    line-height: 27px;
  }
}
@media screen and (max-width: 463px) {
  .question1 p {
    width: 100% !important;
  }
  .question2 p {
    width: 100% !important;
  }
  .contact-heading-container-contained-flex p {
    font-size: 14px;
  }
  .contact-heading-container-contained-flex1 p {
    font-size: 14px;
  }
}
@media screen and (max-width: 449px) {
  .question1 p {
    font-size: 17px;
    line-height: 24px;
  }
  .question2 p {
    font-size: 17px;
    line-height: 24px;
  }
}
@media screen and (max-width: 347px) {
  .Contact-left-small-heading1 {
    margin-bottom: -7%;
    /* background-color: red; */
  }
}
@media screen and (max-width: 339px) {
  .Contact-left-side1 {
    height: 400px;
  }
}
@media screen and (min-width: 348px) and (max-width: 363px) {
  .Contact-left-side1 {
    height: 390px;
  }
}
@media screen and (max-width: 700px) {
  .question {
    margin-top: 5%;
  }
}
@media screen and (max-width: 684px) {
  .Contact-left-side {
    height: 335px;
  }
  .Contact-left-side1 {
    height: 335px;
  }
}
@media screen and (min-width: 901px) and (max-width: 926px) {
  .ok-label label {
    font-size: 15px;
  }
}
@media screen and (max-width: 980px) {
  select {
    font-size: 13px !important;
  }
}
@media screen and (max-width: 930px) {
  select {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 900px) {
  select {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 496px) {
  select {
    font-size: 13px !important;
  }
}
@media screen and (max-width: 462px) {
  select {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 436px) {
  select {
    font-size: 11px !important;
  }
}
@media screen and (max-width: 408px) {
  select {
    font-size: 10px !important;
  }
}

@media screen and (max-width: 463px) {
  .ok-label label {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 437px) {
  .ok-label label {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 411px) {
  .ok-label label {
    font-size: 13px !important;
  }
}
@media screen and (max-width: 385px) {
  .ok-label label {
    font-size: 12px !important;
  }
}
.custom-select1 {
  position: relative;
  width: 100%;
  background-color: white;
}
.custom-select1 select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("../../../public//drop.png") no-repeat right 20px center;
  background-position-y: 55%;
  margin-right: 35px !important;
  background-size: 20px;
  padding-left: 16px;
  cursor: pointer;
}
.custom-select2 {
  position: relative;
  width: 100%;
  background-color: white;
}
.custom-select2 select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("../../../public//drop.png") no-repeat right 20px center;
  background-position-y: 55%;
  margin-right: 35px !important;
  background-size: 20px;
  padding-left: 16px;
  cursor: pointer;
}
select {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  border-radius: 10px;
  outline: none;
  border: none;
  border-radius: 8.5px !important;
  border: 1px solid #b8b8b8;
  /* appearance: none;
  -webkit-appearance: none; */
  -moz-appearance: none;
  padding: 8px;
  color: #6b7280;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  /* background-color: green; */
}
select option:hover {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #ae9c7f !important;
}
select option:active,
select option:hover,
select option :focus {
  background-color: #ae9c7f !important;
}
select option {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  padding-left: 10px;
  /* background-color: red; */
  color: #6b7280;
}
.ddd {
  /* font-family: "Poppins", sans-serif; */
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  padding-left: 10px;
  /* color: #6b7280; */
  /* background-color: purple !important; */
}
.flex-item1 {
  width: 100%;
}
.control-set-flex {
  font-size: 20px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.image-set-contained {
  width: 100%;
}
.image-set-contained img {
  object-fit: cover;
}

@media screen and (max-width: 400px) {
  /* select {
    color: transparent;
  } */
}
@media screen and (max-width: 361px) {
  .ok-label label {
    letter-spacing: -0.5px;
  }
}
@media screen and (max-width: 330px) {
  .ok-label label {
    letter-spacing: -0.6px;
  }
}
@media screen and (max-width: 324px) {
  .ok-label label {
    letter-spacing: -0.7px;
  }
}
@media screen and (max-width: 1300px) {
  .right-testiii {
    height: 805px !important;
  }
}
@media screen and (max-width: 1200px) {
  .right-testiii {
    height: 800px !important;
  }
}
@media screen and (max-width: 1100px) {
  .right-testiii {
    height: 788px !important;
  }
}
@media screen and (max-width: 1000px) {
  .right-testiii {
    height: 780px !important;
  }
}
@media screen and (max-width: 900px) {
  .left-testiii {
    width: 100% !important;
  }
  .testi-img-gallery-pic {
    width: 100% !important;
  }
  .right-testiii {
    width: 100% !important;
    height: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .testi-img-gallery-pic img {
    object-fit: cover !important;
    width: 100% !important;
    height: 100% !important;
    border-radius: 10px !important;
  }
}
@media screen and (min-width: 626px) {
  .input-image-set22 {
    background-image: none;
  }
  .input-image-set23 {
    background-image: none;
  }
  .input-image-set24 {
    background-image: none;
  }
}

@media screen and (max-width: 625px) {
  .goooood input::placeholder {
    color: transparent !important; /* Example text color */
  }
  .flex-item-gallery-contained {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 437px) {
  .hmm-label {
    letter-spacing: -0.5px;
  }
}
@media screen and (max-width: 409px) {
  .hmm-label {
    letter-spacing: -0.9px;
  }
}
@media screen and (max-width: 387px) {
  .hmm-label label {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 363px) {
  .hmm-label label {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 338px) {
  .hmm-label label {
    font-size: 13px !important;
  }
}
@media screen and (max-width: 511px) {
  /* .custom-select2 select {
    color: transparent !important;
  } */
  textarea::placeholder {
    color: transparent !important;
    font-size: 16px;
    font-weight: 600;
    font-family: "SF Pro Display", sans-serif;
    padding-top: 2px;
  }
}
@media screen and (max-width: 562px) {
  /* .custom-select3 select {
    color: transparent !important;
  } */
}
@media screen and (max-width: 490px) {
  .lable-label label {
    letter-spacing: -0.5px;
  }
}
@media screen and (max-width: 450px) {
  .lable-label label {
    letter-spacing: -0.9px;
  }
}
@media screen and (max-width: 425px) {
  .lable-label label {
    font-size: 15px;
  }
}
@media screen and (max-width: 398px) {
  .lable-label label {
    font-size: 14px;
  }
}
@media screen and (max-width: 387px) {
  .lable-label label {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 696px) {
  .form-flex-heading h2 {
    text-align: center !important;
  }
  .form-paraa-flex p {
    font-size: 18px !important;
    line-height: 25px !important;
    text-align: center !important;
  }
}
@media screen and (max-width: 464px) {
  .form-flex-heading h2 {
    text-align: center !important;
    font-size: 35px !important;
  }
}
@media screen and (min-width: 901px) {
  .testi-img-gallery-pic {
    display: none;
  }
}
@media screen and (max-width: 900px) {
  .form-button-gallery {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 1742px) {
  .Contact-main10 {
    max-width: 1440px;
    background-color: #fff7fc;
    /* background-color: green; */
    /* width: 1240px; */
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    /* min-height: 92vh; */
    border-radius: 10px;
    padding-top: 4%;
    /* padding-bottom: 4%; */
    margin-top: -7%;
  }
}

.left-testiii {
  width: 68%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Customize the shadow as needed */
  margin-right: 2%;
}
.right-testiii {
  width: 32%;
  height: 816px;
  /* background-color: orange; */
  margin-bottom: 5%;
  border-radius: 10px;
  background-image: url("../../Assets-Dental//image11.jpg");
  background-size: cover;
  background-position-x: 40%;
}

.form-flex-heading {
  width: 100%;
}
.form-flex-heading h2 {
  font-size: 45px;
  font-weight: 600;
  font-family: "SF Pro Display", sans-serif;
  color: hsl(240, 6%, 10%);
}
.form-paraa-flex {
  width: 100%;
}
.form-paraa-flex p {
  font-size: 21px;
  font-weight: 500;
  font-family: "SF Pro Display", sans-serif;
  color: #18181b;
}

.contact-right-input-done input {
  padding-left: 30px !important;
}
.done-flex ::placeholder {
  padding-left: 5px;
}

.contact-right-input ::placeholder {
  color: rgba(177, 176, 176, 0.589) !important;
  font-size: 16px;
  font-weight: 600;
  font-family: "SF Pro Display", sans-serif;
}

textarea::placeholder {
  color: rgba(177, 176, 176, 0.589);
  font-size: 16px;
  font-weight: 600;
  font-family: "SF Pro Display", sans-serif;
  padding-top: 2px;
}
.custom-select2 select {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  border-radius: 10px;
  outline: none;
  border: none;
  border-radius: 8.5px !important;
  border: 1px solid #b8b8b8;
  /* appearance: none;
  -webkit-appearance: none; */
  -moz-appearance: none;
  padding: 8px;
  color: rgba(177, 176, 176, 0.589);
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  padding-left: 4%;
  /* background-color: green; */
}
.form-button-gallery {
  width: 100%;
}
.form-button-gallery button {
  background-color: #d521a0;
  border-radius: 25px;
  width: 145px;
  height: 51px;
  color: white;
  line-height: 22px;
  font-size: 18px;
  font-weight: 500;
  border: none;
  outline: none;
  font-family: "SF Pro Display", sans-serif;
}
.flex-item-gallery-contained {
  display: flex;
  width: 100%;
  gap: 20px;
}
.ok-labellll {
  margin-top: 3px;
}
.bbbtttt input {
  padding-left: 9% !important;
}
.flex-email-phone-container {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-top: 2%;
}
@media screen and (max-width: 1200px) {
  .flex-email-phone-container {
    display: flex;
    flex-direction: column;
    margin-top: -1%;
  }
  .phone-label-set label {
    margin-top: -1%;
  }
}
@media screen and (max-width: 900px) {
  .flex-email-phone-container {
    display: flex !important;
    justify-content: center !important;
    width: 100% !important;
    flex-direction: row !important;
    align-items: center !important;
    margin-top: 0%;
  }
  .phone-label-set label {
    margin-top: 3%;
  }
  .peal-label {
    margin-top: 1%;
  }
  .msg-label {
    margin-top: 0%;
  }
}
@media screen and (max-width: 700px) {
  .flex-email-phone-container {
    display: flex !important;
    /* justify-content: center !important; */
    width: 100% !important;
    flex-direction: column !important;
    /* align-items: center !important; */
    margin-top: 0%;
  }
  .phone-label-set {
    margin-top: 0%;
  }
}
@media screen and (max-width: 1200px) {
  .lusia-flus {
    padding-bottom: 30px;
  }
  .set-image-set {
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 900px) {
  .lusia-flus {
    padding-bottom: 0;
  }
  .set-image-set {
    padding-bottom: 0;
  }
  .set-image-set {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .set-image-set img {
    width: 100%;
  }
}
@media screen and (max-width: 564px) {
  .lusia-flus {
    width: 40%;
  }
}
@media screen and (max-width: 493px) {
  .lusia-flus {
    width: 50%;
  }
}
@media screen and (max-width: 410px) {
  .lusia-flus {
    width: 60%;
  }
}
@media screen and (max-width: 350px) {
  .lusia-flus {
    width: 70%;
  }
}
@media screen and (min-width: 590px) and (max-width: 900px) {
  .flex-flex-head {
    padding-top: 30px;
  }
}
@media screen and (max-width: 625px) {
  .content-flex input {
    padding-left: 4% !important;
  }
}
@media screen and (min-width: 626px) {
  .email-labelll label {
    margin-top: 20px;
  }
}
@media screen and (max-width: 625px) {
  .lll-label label {
    margin-top: 25px;
  }
  .label-set-max label {
    margin-top: 3px;
  }
}
@media screen and (min-width: 1254px) {
  .address-max {
    margin-top: 10px;
  }
}
@media screen and (max-width: 900px) {
  .email-fluxx {
    margin-top: 2px;
  }
}
@media screen and (max-width: 625px) {
  .phone-max-flex label {
    margin-top: -2px;
  }
  .set-indirect input {
    padding-left: 15px !important;
  }
}
