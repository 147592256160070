@media only screen and (max-width: 973px) {
  .about-para-invis p {
    font-size: 15px;
    line-height: 20px;
  }
}
@media only screen and (max-width: 865px) {
  .about-para-invis p {
    font-size: 15px;
    line-height: 20px;
  }
}
@media only screen and (min-width: 606px) and (max-width: 650px) {
  .about-para-invis p {
    font-size: 14px;
    line-height: 20px;
  }
}
@media only screen and (max-width: 757px) {
  .about-para-invis {
    width: 95%;
  }
  .about-right-img10 img {
    object-fit: cover;
    object-position: -40px;
    height: 100%;
    width: 100%;
    /* z-index: -1; */
  }
  .about-right-img11 img {
    object-fit: cover;
    object-position: -60px;
    height: 100%;
    width: 100%;

    /* z-index: -1; */
  }
}
@media only screen and (max-width: 684px) {
  .about-right-img11 img {
    object-fit: cover;
    object-position: -70px;
    height: 100%;
    width: 100%;
  }
}
@media only screen and (max-width: 605px) {
  .about-para-invis p {
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .about-right-img10 img {
    object-fit: contain;
    object-position: 70%;
  }
  .about-right-img11 img {
    object-fit: contain;
    object-position: 70%;
  }
}
@media only screen and (max-width: 1220px) {
  .about-heading-invis h2 {
    font-size: 50px;
  }
  .about-heading-invis-dental h2 {
    font-size: 50px;
  }
}
@media only screen and (max-width: 1089px) {
  .about-heading-invis h2 {
    font-size: 45px;
  }
  .about-heading-invis-dental h2 {
    font-size: 45px;
  }
}
@media only screen and (max-width: 980px) {
  .about-heading-invis h2 {
    font-size: 40px;
    line-height: 50px;
  }
  .about-heading-invis-dental h2 {
    font-size: 40px;
    line-height: 50px;
  }
}
@media only screen and (max-width: 871px) {
  .about-heading-invis h2 {
    font-size: 35px;
    line-height: 45px;
  }
  .about-heading-invis-dental h2 {
    font-size: 35px;
    line-height: 45px;
  }
}
@media only screen and (min-width: 758px) and (max-width: 762px) {
  .about-heading-invis h2 {
    font-size: 34px;
    line-height: 45px;
  }
  .about-heading-invis-dental h2 {
    font-size: 34px;
    line-height: 45px;
  }
}
@media only screen and (min-width: 606px) and (max-width: 610px) {
  .about-heading-invis h2 {
    font-size: 33px;
    line-height: 45px;
  }
  .about-heading-invis-dental h2 {
    font-size: 33px;
    line-height: 45px;
  }
}
/* @media only screen and (max-width: 404px) {
  .about-heading-invis h2 {
    font-size: 25px;
    line-height: 40px;
  }
} */
@media only screen and (max-width: 605px) {
  .about-heading-invis {
    width: 100%;
  }
  .about-heading-invis-dental {
    width: 100%;
  }
}
@media only screen and (max-width: 403px) {
  .about-heading-invis {
    width: 100%;
    display: flex;
    /* background-color: red; */
    justify-content: center;
    align-items: center;
  }
  .about-heading-invis h2 {
    /* background-color: green; */
    width: 100%;
    line-height: 40px;
    font-size: 30px;
  }
  .about-heading-invis-dental {
    width: 100%;
    display: flex;
    /* background-color: red; */
    justify-content: center;
    align-items: center;
  }
  .about-heading-invis-dental h2 {
    /* background-color: green; */
    width: 100%;
    line-height: 40px;
    font-size: 30px;
  }
}
@media only screen and (max-width: 341px) {
  .about-heading-invis h2 {
    font-size: 28px;
  }
  .about-heading-invis-dental h2 {
    font-size: 28px;
  }
}
@media only screen and (max-width: 1403px) {
  .about-heading-invis-dental {
    width: 100%;
  }
}
@media only screen and (min-width: 1221px) and (max-width: 1262px) {
  .about-heading-invis-dental h2 {
    font-size: 50px;
  }
}
@media only screen and (min-width: 1090px) and (max-width: 1127px) {
  .about-heading-invis-dental h2 {
    font-size: 45px;
  }
}
@media only screen and (min-width: 980px) and (max-width: 1014px) {
  .about-heading-invis-dental h2 {
    font-size: 40px;
  }
}
@media only screen and (min-width: 871px) and (max-width: 902px) {
  .about-heading-invis-dental h2 {
    font-size: 35px;
  }
}
@media only screen and (min-width: 758px) and (max-width: 789px) {
  .about-heading-invis-dental h2 {
    font-size: 33px;
  }
}
@media only screen and (min-width: 611px) and (max-width: 631px) {
  .about-heading-invis-dental h2 {
    font-size: 32px;
  }
}

/* RESPONSIVE CODE FOR "invisEnlighten" */
@media only screen and (max-width: 1393px) {
  .about-heading-invis-dental-enlight h2 {
    font-size: 45px;
    line-height: 62px;
  }
}
@media only screen and (max-width: 1306px) {
  .about-heading-invis-dental-enlight h2 {
    font-size: 40px !important;
    line-height: 62px !important;
  }
}
@media only screen and (max-width: 1161px) {
  .about-heading-invis-dental-enlight h2 {
    font-size: 35px !important;
    line-height: 50px !important;
  }
}
@media only screen and (max-width: 1016px) {
  .about-heading-invis-dental-enlight h2 {
    font-size: 30px !important;
    line-height: 42px !important;
  }
}
@media only screen and (max-width: 870px) {
  .about-heading-invis-dental-enlight h2 {
    font-size: 25px !important;
    line-height: 35px !important;
  }
}

@media only screen and (min-width: 1307px) and (max-width: 1440px) {
  .discrete h2 {
    font-size: 42px;
  }
}
@media only screen and (min-width: 1162px) and (max-width: 1235px) {
  .discrete h2 {
    font-size: 35px;
  }
}
@media only screen and (min-width: 1017px) and (max-width: 1081px) {
  .discrete h2 {
    font-size: 30px;
  }
}
@media only screen and (min-width: 871px) and (max-width: 926px) {
  .discrete h2 {
    font-size: 25px;
  }
}
@media only screen and (min-width: 758px) and (max-width: 772px) {
  .discrete h2 {
    font-size: 23px;
  }
}
@media only screen and (min-width: 606px) and (max-width: 617px) {
  .discrete h2 {
    font-size: 22px;
  }
}
@media only screen and (min-width: 1307px) {
  .about-heading-invis-dental-set {
    width: 120% !important;
    /* background-color: red; */
  }
}
@media only screen and (min-width: 1162px) and (max-width: 1235px) {
  .accordion-heading-set h2 {
    font-size: 38px !important;
    line-height: 55px !important;
  }
}
@media only screen and (min-width: 1162px) and (max-width: 1173px) {
  .accordion-heading-set h2 {
    font-size: 36px !important;
    line-height: 55px !important;
  }
}
@media only screen and (min-width: 1017px) and (max-width: 1081px) {
  .accordion-heading-set h2 {
    font-size: 32px !important;
    line-height: 48px !important;
  }
}
@media only screen and (min-width: 871px) and (max-width: 926px) {
  .accordion-heading-set h2 {
    font-size: 28px !important;
    line-height: 43px !important;
  }
}
@media only screen and (min-width: 758px) and (max-width: 772px) {
  .accordion-heading-set h2 {
    font-size: 22px !important;
    line-height: 35px !important;
  }
}
