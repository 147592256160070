@import url("https://fonts.cdnfonts.com/css/mona-sans");
@import url("https://fonts.cdnfonts.com/css/kollektif");
@import url("https://fonts.cdnfonts.com/css/sf-pro-display");
.expert-right-content-container1 {
  width: 94%;
  /* padding-top: 2%; */
  /* background-color: yellow; */
}
.expert-container-contained1 {
  /* background-color: pink; */
  display: flex;
  width: 88%;
}
.expert-right1 {
  width: 44%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.expert-left1 {
  width: 56%;
}
.expert-para-container1 {
  width: 99.5%;
}
.expert-para-container1 p {
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
}
.expert-heading-container1 {
  width: 100%;
  padding-bottom: 2%;
}
.expert-heading-container1 p {
  font-family: "SF Pro Display", sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 30px;
}
.expert-content1 {
  display: flex;
  width: 100%;
  /* background-color: yellow; */
}
.expert-img1 {
  width: 100%;
  display: flex;
  align-items: center;
  /* background-color: red; */
}
.expert-img1 img {
  object-fit: cover;
}
.expert-heading-control1 {
  color: #d521a0;
  font-family: "SF Pro Display", sans-serif;
  font-size: 20px;
  margin-left: 1%;
  font-weight: 500;
}
