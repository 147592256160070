@import url("https://fonts.cdnfonts.com/css/mona-sans");
@import url("https://fonts.cdnfonts.com/css/kollektif");
@import url("https://fonts.cdnfonts.com/css/sf-pro-display");

.Footer {
  height: auto;
  /* min-height: 100vh; */
  /* max-width: 1700px; */
  /* max-width: 1440px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #0f0f10;
  /* height: 200px; */
  display: flex;
  justify-content: center;
}

.Footer-main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* height: 100%; */
  height: 200px;
  /* background-color: red; */
  margin-bottom: 4%;
  /* background-color: orange; */
}

.Footer-upper-section {
  width: 86%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  height: 170px;
  margin-top: 2%;
  /* background-color: red; */
}

.footer-upper-section-logo {
  /* width: 214px; */
  width: 17%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.footer-upper-section-logo img {
  /* width: 214px; */
  /* width: 100%; */
  /* background-color: red; */
  object-fit: contain;
  width: 140px;
}

.footer-upper-section-line {
  /* width: 450px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 37%;
  border: 1px solid #333333;
  margin-right: 2%;
}

.Footer-upper-section-timing {
  /* width: 263px; */
  width: 23%;
  height: 41px;
  border-radius: 108px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #333333;
}

.Days {
  /* width: 113px; */
  width: 44%;
  padding-top: 6%;
}
.Days p {
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
}
.Line {
  /* width: 15px; */
  width: 6%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  /* font-size: 19px; */
  padding-top: 2%;
  color: gray;
  padding-left: 1%;
  /* background-color: red; */
}
.Line1 {
  /* width: 15px; */
  width: 6%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  /* font-size: 19px; */
  padding-top: 6px;
  color: gray;
  padding-left: 2%;
}
.Line2 {
  /* width: 15px; */
  width: 6%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  /* font-size: 19px; */
  padding-top: 6px;
  color: gray;
  /* padding-left: 2%; */
}

.Time {
  /* width: 86px; */
  width: 33%;
  margin-top: 6%;
}
.msgicon-email {
  margin-top: -4px;
}
.Time p {
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
}

.Footer-upper-section-email {
  /* width: 263px; */
  width: 21%;
  height: 41px;
  border-radius: 108px;
  background-color: #333333;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-left: 5px;
}

.Email {
  /* width: 63px; */
  width: 25%;
  margin-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.Emailname {
  /* width: 139px; */
  width: 54%;
  margin-top: 6%;
}

.Emailname p {
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
}
.Email {
  padding-top: 1%;
}
.Email p {
  font-size: 16px;
}

.Footer-lower-section {
  width: 86%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: 1%;
  /* background-color: red; */
}

.Footer-lower-section-menu {
  /* width: 454px; */
  width: 85%;
  /* background-color: red; */
  display: flex;
  align-items: center;
  /* background-color: red; */
  gap: 5%;
}
.footer-flex-flus {
  width: 37%;
  /* background-color: purple; */
  display: flex;
  justify-content: end;
}
.insta-img-sett img {
  width: 50px;
}
.profile-setup {
  /* background-color: orange; */
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  text-decoration: none;
  color: white;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
}
.Footer-lower-section-menu ul {
  width: 100%;
  list-style: none;
  padding: 0;
  padding-left: 2%;
  background-color: orange;
  display: flex;
  align-items: center;
}

.Footer-lower-section-menu ul li a {
  font-size: 14px;
  text-decoration: none;
  color: white;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
}

.Footer-copyright {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* width: 280px; */
  /* width: 20%; */
}
.copyright-data {
  width: 100%;
  /* background-color: red; */
  display: flex;
  align-items: end;
  justify-content: end;
  flex-direction: column;
}
.copyright-data p {
  color: white;
  font-size: 14px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 400;
  line-height: 10px;
}

.Footer-lower-section-email {
  /* width: 263px; */
  width: 21%;
  height: 41px;
  border-radius: 108px;
  background-color: #333333;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}

.lower-copyright {
  display: none;
}
.lower-buttons {
  display: none;
}

/* Media queries */

/* For email icon image and email*/

@media screen and (max-width: 1393px) {
  .Email p img {
    width: 19px;
  }
}

@media screen and (max-width: 1370px) {
  .Email p img {
    width: 18px;
  }
}
@media screen and (max-width: 1342px) {
  .Footer-upper-section-timing {
    width: 22%;
  }
  .Footer-upper-section-email {
    width: 22%;
  }
}
@media screen and (max-width: 1281px) {
  .Footer-upper-section-timing {
    width: 23%;
  }
  .Footer-upper-section-email {
    width: 23%;
  }
  .footer-upper-section-line {
    width: 34%;
  }
}
@media screen and (max-width: 1225px) {
  .Footer-upper-section-timing {
    width: 24%;
  }
  .Footer-upper-section-email {
    width: 24%;
  }
  .footer-upper-section-line {
    width: 32%;
  }
}
@media screen and (max-width: 1174px) {
  .Footer-upper-section-timing {
    width: 25%;
  }
  .Footer-upper-section-email {
    width: 25%;
  }
  .footer-upper-section-line {
    width: 30%;
  }
}
@media screen and (max-width: 1127px) {
  .Footer-upper-section-timing {
    width: 26%;
  }
  .Footer-upper-section-email {
    width: 26%;
  }
  .footer-upper-section-line {
    width: 28%;
  }
}
@media screen and (max-width: 1127px) {
  .Footer-upper-section-timing {
    width: 27%;
  }
  .Footer-upper-section-email {
    width: 27%;
  }
  .footer-upper-section-line {
    width: 26%;
  }
}
@media screen and (max-width: 1044px) {
  .Footer-upper-section-timing {
    width: 28%;
  }
  .Footer-upper-section-email {
    width: 28%;
  }
  .footer-upper-section-line {
    width: 24%;
  }
}
@media screen and (max-width: 1006px) {
  .Footer-upper-section-timing {
    width: 29%;
  }
  .Footer-upper-section-email {
    width: 29%;
  }
  .footer-upper-section-line {
    width: 22%;
  }
}
@media screen and (max-width: 1348px) {
  .Email p img {
    width: 17px;
  }
  .insta-btn-set {
    width: 98% !important;
    /* background-color: red; */
  }
}

/* @media screen and (max-width: 1325px) {
  .Email p img {
    width: 16px;
  }
}

@media screen and (max-width: 1325px) {
  .Email p {
    font-size: 15px;
  }
} */

/* @media screen and (max-width: 1244px) {
  .Email p {
    font-size: 14px;
  }
} */

/* @media screen and (max-width: 1185px) {
  .Email p img {
    width: 15px;
  }
} */

/* @media screen and (max-width: 1163px) {
  .Email p img {
    width: 14px;
  }
} */
@media screen and (max-width: 1156px) {
  .msgicon-email {
    margin-top: -3px;
  }
}
/* @media screen and (max-width: 1141px) {
  .Email p {
    font-size: 13px;
  }
} */

/* @media screen and (max-width: 1081px) {
  .Email p {
    font-size: 12px;
  }
} */

/* For days Monday-friday and timing 9AM-5PM */

/* @media screen and (max-width: 1365px) {
  .Time p {
    font-size: 15px;
  }
} */

/* @media screen and (max-width: 1363px) {
  .Days p {
    font-size: 15px;
  }
} */

/* @media screen and (max-width: 1281px) {
  .Time p {
    font-size: 14px;
  }
} */

/* @media screen and (max-width: 1278px) {
  .Days p {
    font-size: 14px;
  }
} */

@media screen and (max-width: 1195px) {
  /* .Time p {
    font-size: 13px;
  } */
}
/* 
@media screen and (max-width: 1174px) {
  .Days p {
    font-size: 13px;
  }
  .Time p {
    font-size: 13px;
  }
} */

@media screen and (max-width: 1109px) {
  /* .Time p {
    font-size: 13px;
  } */
}

/* @media screen and (max-width: 1024px) {
  .Time p {
    font-size: 11px;
  }
} */

/* @media screen and (max-width: 1090px) {
  .Days p {
    font-size: 12px;
  }
  .Time p {
    font-size: 12px;
  }
} */
/* 
@media screen and (max-width: 1022px) {
  .Footer-upper-section-timing {
    width: 22%;
  }
  .Footer-upper-section-email {
    width: 22%;
  }
} */

/* For top email */
@media screen and (max-width: 1393px) {
  .Emailname p {
    font-size: 16px;
  }
}

/* @media screen and (max-width: 1330px) {
  .Emailname p {
    font-size: 15px;
  }
} */

/* @media screen and (max-width: 1250px) {
  .Emailname p {
    font-size: 14px;
  }
} */

/* @media screen and (max-width: 1180px) {
  .Emailname p {
    font-size: 13px;
  }
} */

/* @media screen and (max-width: 1065px) {
  .Emailname p {
    font-size: 12px;
  }
} */

/* footer menu */

/* @media screen and (max-width: 1352px) {
  .Footer-lower-section-menu {
    width: 40%;
  }
} */

/* @media screen and (max-width: 1220px) {
  .Footer-lower-section-menu {
    width: 43%;
  }
} */

/* @media screen and (max-width: 1170px) {
  .Footer-lower-section-menu {
    width: 45%;
  }
}

@media screen and (max-width: 1070px) {
  .Footer-lower-section-menu {
    width: 47%;
  }
} */

@media screen and (min-width: 601px) and (max-width: 1000px) {
  .Footer-lower-section {
    display: flex;
    justify-content: space-between;
    margin-top: 0%;
  }

  .Footer-upper-section {
    justify-content: space-between;

    /* background-color: red; */
  }

  .Footer-upper-section-timing {
    width: 30%;
  }

  .footer-upper-section-line {
    width: 50%;
    margin-left: 1%;
  }

  .Footer-lower-section-email {
    display: flex;
    width: 30%;
  }

  .Footer-upper-section-email {
    display: none;
  }

  .Footer-copyright {
    display: none;
  }

  .lower-copyright {
    display: flex;
  }
  .Footer-lower-section-menu ul li a {
    font-size: 13px;
  }
  .copyright-data {
    padding-top: 45px;
    padding-bottom: 15px;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .Footer-main-container {
    height: 260px;
    padding-bottom: 1%;
    margin-top: 40px;
  }
  .footer-flex-flus {
    margin-top: 15px;
  }
}
@media screen and (max-width: 840px) {
  .footer-upper-section-line {
    width: 50%;
    margin-right: 4px;
  }
}
@media screen and (min-width: 601px) and (max-width: 670px) {
  .footer-upper-section-logo img {
    margin-left: 17px;
  }
  .footer-upper-section-line {
    width: 38% !important;
    margin-left: 3% !important;
  }
}
@media screen and (max-width: 829px) {
  .Footer-main-container {
    /* margin-top: 4%; */
  }
  .copyright-data {
    padding-top: 10% !important;
    padding-bottom: 15px;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
@media screen and (max-width: 400px) {
  .copyright-data p {
    font-size: 13px;
  }
}
@media screen and (max-width: 364px) {
  .copyright-data p {
    font-size: 12px;
  }
}
@media screen and (max-width: 340px) {
  .copyright-data p {
    letter-spacing: -0.2px;
  }
}
@media screen and (max-width: 833px) {
  .copyright-data {
    /* padding-top: 15px; */
  }
  .Footer-upper-section {
    /* justify-content: space-between; */
    margin-top: 3%;
    /* background-color: red; */
  }
}
@media screen and (max-width: 939px) {
  .footer-upper-section-line {
    width: 48%;
  }
  .Footer-upper-section-timing {
    width: 32%;
  }
  .Footer-lower-section-email {
    width: 32%;
  }
}
@media screen and (max-width: 881px) {
  .footer-upper-section-line {
    width: 46%;
  }
  .Footer-upper-section-timing {
    width: 34%;
  }
  .Footer-lower-section-email {
    width: 34%;
  }
}
@media screen and (max-width: 829px) {
  .footer-upper-section-line {
    width: 44%;
  }
  .Footer-upper-section-timing {
    width: 36%;
  }
  .Footer-lower-section-email {
    width: 36%;
  }
}
@media screen and (max-width: 783px) {
  .footer-upper-section-line {
    width: 42%;
  }
  .Footer-upper-section-timing {
    width: 38%;
  }
  .Footer-lower-section-email {
    width: 38%;
  }
}
@media screen and (max-width: 742px) {
  .footer-upper-section-line {
    width: 40%;
  }
  .Footer-upper-section-timing {
    width: 40%;
  }
  .Footer-lower-section-email {
    width: 40%;
  }
}
@media screen and (max-width: 704px) {
  .Days p {
    font-size: 15px;
  }
  .Time p {
    font-size: 15px;
  }
  .Email p {
    font-size: 15px;
  }
  .Emailname p {
    font-size: 15px;
  }
  .Email p img {
    margin-top: -7% !important;
    /* background-color: red; */
  }
  .Footer-upper-section {
    width: 95%;
  }
  .Footer-lower-section {
    width: 95%;
  }
}
@media screen and (max-width: 640px) {
  .footer-upper-section-line {
    width: 44%;
  }
  .Footer-upper-section-timing {
    width: 36%;
  }
  .Footer-lower-section-email {
    width: 36%;
  }
  .Days p {
    font-size: 14px;
  }
  .Time p {
    font-size: 14px;
  }
  .Email p {
    font-size: 14px;
  }
  .Emailname p {
    font-size: 14px;
  }
}
@media screen and (max-width: 630px) {
  .Days p {
    font-size: 13px;
  }
  .Time p {
    font-size: 13px;
  }
  .Email p {
    font-size: 13px;
  }
  .Emailname p {
    font-size: 13px;
  }
}

@media screen and (min-width: 601px) and (max-width: 696px) {
  .Footer-lower-section-menu {
    width: 90%;
  }
}
@media screen and (min-width: 601px) and (max-width: 658px) {
  .profile-setup {
    white-space: nowrap;
  }
}
/* @media screen and (max-width: 900px) {
  .Footer-lower-section {
    width: 92%;
    display: flex;
    justify-content: space-between;
  }
} */

/* @media screen and (max-width: 1000px) {
  .Footer-lower-section {
    width: 91%;
    display: flex;
    justify-content: space-between;
  }
} */

/* @media screen and (max-width:1000px){
    .Footer-lower-section-email{
        margin-right: 1.75%;
    }
    
} */
@media screen and (max-width: 600px) {
  .Footer-main-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .Footer-upper-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 70px;
  }
  .footer-upper-section-logo {
    margin-bottom: 10px;
  }
  .Footer-lower-section {
    flex-direction: column-reverse;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Footer-upper-section-timing {
    display: none;
  }

  .Footer-upper-section-email {
    display: none;
  }

  .Days {
    padding-top: 6%;
  }

  .Line {
    padding-top: 0;
  }
  .Time {
    margin-top: 7%;
  }
  .Time p {
    font-size: 15px;
  }
  .Days p {
    font-size: 15px;
  }
  .footer-upper-section-logo img {
    width: 130px;
  }
  .lower-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5%;
    height: 100%;
  }
  .Footer-copyright {
    display: none;
  }
  .lower-copyright {
    display: flex;
  }
  .Footer-upper-section-timing2 {
    width: 300px;

    height: 41px;
    border-radius: 108px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #333333;
    /* background-color: yellow; */
  }
  .Footer-upper-section-email2 {
    width: 300px;
    /* width: 85%; */
    height: 41px;
    border-radius: 108px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #333333;
    /* background-color: pink; */
  }
  .Email p {
    font-size: 15px;
  }
  .Emailname p {
    font-size: 15px;
  }
  .footer-upper-section-line {
    width: 90%;
  }
  .copyright-data {
  }

  .Footer-lower-section-menu {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .profile-setup {
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .profile-setup {
    white-space: nowrap;
  }
  .insta-img-sett {
    margin-bottom: 10%;
  }
  .footer-flex-flus {
    /* background-color: orange; */
    display: flex;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 40px;
  }
  .Footer {
    height: auto !important;
  }
  .Footer-main-container {
    height: auto !important;
  }
}
@media screen and (max-width: 400px) {
  .footer-flex-flus {
    padding-bottom: 52px;
  }
}
/* @media screen and (max-width: 560px) {
  .Footer-lower-section-menu {
    margin-top: 2%;
  }
}
@media screen and (max-width: 533px) {
  .Footer-lower-section-menu {
    margin-top: 4%;
  }
} */
/* @media screen and (max-width: 500px) {
  .Footer-lower-section-menu {
    margin-top: 6%;
  }
  .Footer-lower-section {
    margin-top: 5%;
  }
} */
/* @media screen and (max-width: 470px) {
  .Footer-lower-section-menu {
    margin-top: 8%;
  }
  .Footer-lower-section {
    margin-top: 6%;
  }
} */
/* @media screen and (max-width: 440px) {
  .Footer-lower-section-menu {
    margin-top: 10%;
  }
  .Footer-lower-section {
    margin-top: 8%;
  }
  .Footer-main-container {
    height: 350px;
  }
} */
@media screen and (max-width: 440px) {
  .parraaaa-set {
    width: 90%;
    line-height: 20px !important;
  }
}

/* @media screen and (max-width: 450px) {
  .Footer-lower-section-menu ul li a {
    font-size: 12px;
  }
}
@media screen and (max-width: 357px) {
  .Footer-lower-section-menu ul li a {
    font-size: 10px;
  }
}
@media screen and (max-width: 323px) {
  .Footer-lower-section-menu ul li a {
    white-space: nowrap;
  }
} */

@media screen and (max-width: 399px) {
  .Footer-upper-section-timing2 {
    width: 230px;
  }
  .Footer-upper-section-email2 {
    width: 230px;
  }
  .Email p {
    font-size: 13px;
  }
  .Emailname p {
    font-size: 13px;
  }
  .Time p {
    font-size: 13px;
  }
  .Days p {
    font-size: 13px;
  }
}
@media screen and (max-width: 340px) {
  .Footer-upper-section-timing2 {
    width: 220px;
  }
  .Footer-upper-section-email2 {
    width: 220px;
  }
  .Email p {
    font-size: 12px;
  }
  .Emailname p {
    font-size: 12px;
  }
  .Time p {
    font-size: 12px;
  }
  .Days p {
    font-size: 12px;
  }
}
@media screen and (min-width: 1500px) {
  .Line {
    padding-left: 0%;
  }
}
@media screen and (min-width: 1600px) {
  .Line {
    margin-left: -3%;
    width: 5%;
  }
}
@media screen and (min-width: 1700px) {
  .Line {
    margin-top: 4%;
  }
  .Footer-upper-section-timing {
    height: 50px;
  }
  .Days p {
    font-size: 17px;
  }
  .Time p {
    font-size: 17px;
  }
  .Footer-upper-section-email {
    height: 50px;
    /* background-color: red; */
  }
  .Email p {
    font-size: 17px;
  }
  .Emailname p {
    font-size: 17px;
  }
  .Line1 {
    margin-top: 4%;
  }
}
@media screen and (min-width: 1800px) {
  .Line {
    margin-top: 3%;
    margin-left: -5%;
  }
}
@media screen and (min-width: 1900px) {
  .Line {
    margin-left: -6%;
  }
}
@media screen and (min-width: 2000px) {
  .Line {
    margin-left: -8%;
  }
  .Line1 {
    margin-left: -1%;
  }
}
@media screen and (min-width: 1217px) {
  .footer-upper-section-line {
    margin-left: -2%;
  }
}
@media screen and (min-width: 1100px) {
  .footer-upper-section-line {
    margin-left: -1%;
  }
}
@media screen and (min-width: 1001px) and (max-width: 1348px) {
  .Footer-main-container {
    width: 95%;
    /* background-color: orange; */
  }
  .Footer-upper-section {
    width: 100%;
    /* background-color: red; */
  }
  .Footer-lower-section {
    width: 98%;
    /* background-color: red; */
  }
}
@media screen and (min-width: 601px) and (max-width: 1000px) {
  .Footer-main-container {
    width: 95%;
    /* background-color: orange; */
  }
  .Footer-upper-section {
    width: 100%;
    /* background-color: red; */
  }
  .Footer-lower-section {
    width: 100%;
    /* background-color: red; */
    margin-left: 5px;
  }
}
@media screen and (min-width: 601px) and (max-width: 658px) {
  .Footer-upper-section-timing {
    /* width: 263px; */
    width: 42%;
    /* background-color: red; */
  }

  .footer-upper-section-line {
    width: 30% !important;
  }
  .Footer-lower-section-email {
    width: 42%;
    height: 41px;
    border-radius: 108px;
    background-color: #333333;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 601px) {
  .flex-set-hex {
    display: none;
  }
}
.insta-btn-set {
  width: 86%;
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: end;
}
@media screen and (max-width: 1000px) {
  .insta-btn-set {
    display: none;
  }
}
@media screen and (min-width: 1001px) {
  .Footer {
    height: 300px;
  }
  .Footer-upper-section {
    margin-top: 4%;
    /* background-color: red; */
  }
}
@media screen and (min-width: 601px) and (max-width: 1000px) {
  .set-insta-face {
    /* background-color: red; */
    display: flex;
    justify-content: center;
    margin-bottom: -30px;
  }
  .lower-nsta-set-max {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) {
  .set-insta-face {
    display: none;
  }
}
@media screen and (min-width: 1001px) {
  .set-insta-face {
    display: none;
  }
}
