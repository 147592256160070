@media only screen and (max-width: 1211px) {
  .about-heading h2 {
    font-size: 50px;
  }
  .about-container-contained-leftt {
    margin-bottom: 0%;
  }
}
@media only screen and (max-width: 1081px) {
  .about-heading h2 {
    font-size: 45px;
  }
  .about-container-contained-leftt {
    margin-top: 4%;
  }
}
@media only screen and (max-width: 973px) {
  .about-container-contained-leftt {
    margin-top: 8%;
  }
  .about-heading h2 {
    font-size: 40px;
    line-height: 50px;
  }
  .about-para p {
    font-size: 15px;
    line-height: 20px;
  }
}
@media only screen and (max-width: 865px) {
  .about-container-contained-leftt {
    margin-top: 12%;
  }
  .about-heading h2 {
    font-size: 35px;
    line-height: 45px;
  }
  .about-para p {
    font-size: 15px;
    line-height: 20px;
  }
}
@media only screen and (max-width: 850px) {
  .about-button {
    margin-bottom: 5%;
  }
}
@media only screen and (max-width: 757px) {
  .about-container-left {
    width: 50%;
  }
  .about-container-right {
    width: 50%;
  }
  .about-container-contained-leftt {
    margin-top: 12%;
  }
  .about-heading h2 {
    font-size: 35px;
    line-height: 45px;
  }
  .about-para {
    width: 95%;
  }

  .about-button button {
    width: 152px;
    height: 55px;
  }
}
@media only screen and (max-width: 605px) {
  .about-container-contained {
    flex-direction: column-reverse;
    /* background-color: orangered; */
  }
  .about-container-left {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: yellow; */
  }
  .about-container-right {
    width: 100%;
  }
  .about-heading {
    width: 100%;
    /* background-color: red; */
    /* margin-top: -10%; */
  }

  .about-button {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 6%;
  }
  .about-right-img img {
    object-fit: contain;
    object-position: 70%;
  }
  .about-para p {
    padding-top: 2%;
    padding-bottom: 2%;
  }
}
@media only screen and (max-width: 403px) {
  .about-heading {
    width: 100%;
    display: flex;
    /* background-color: red; */
    justify-content: center;
    align-items: center;
  }
  .about-heading h2 {
    /* background-color: green; */
    width: 65%;
    line-height: 40px;
    font-size: 33px;
  }
}
@media only screen and (max-width: 329px) {
  .about-heading h2 {
    width: 70%;
  }
}
