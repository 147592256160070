@import url("https://fonts.cdnfonts.com/css/mona-sans");
@import url("https://fonts.cdnfonts.com/css/kollektif");
@import url("https://fonts.cdnfonts.com/css/sf-pro-display");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.slider-contained-container-full {
  width: 100%;
  display: flex;
  justify-content: center;
  /* background-color: red; */
}
.slider-full-contained {
  max-width: 1440px;
  width: 100%;
  /* background-color: yellow; */
}
.slider-contained {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* background-color: orange; */
  /* height: 73vh; */
  height: auto;
  margin-top: 4%;
  margin-bottom: 2%;
  /* background-color: green; */
}
.slider-conatineer-contained {
  width: 84%;
  /* background-color: purple; */
}
.slider-heading {
  width: 100%;
  margin-left: 3px;
}
.slider-heading h2 {
  font-size: 18px;
  color: #d521a0;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
  line-height: 25px;
}
.slider-para {
  width: 100%;
}
.slider-para p {
  font-size: 40px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 600;
  line-height: 50px;
}
.container15 {
  width: 100%;
  /* background-color: orange; */
}
.container-contained-wrapper {
  width: 100%;
  /* background-color: yellow; */
  margin-left: 16%;
}

.slider-wrapper {
  position: relative;
  /* background-color: red; */
  width: 100%;
  overflow: hidden;
  /* margin-left: 4%; */
}

.slider-wrapper .slide-button {
  position: absolute;
  top: 50%;
  outline: none;
  border: none;
  /* height: 50px; */
  /* width: 50px; */
  /* z-index: 5; */
  color: #fff;
  display: flex;
  cursor: pointer;
  font-size: 2.2rem;
  /* background: #000; */
  align-items: center;
  justify-content: center;
  /* border-radius: 50%; */
  transform: translateY(-50%);
}

.slider-wrapper .slide-button:hover {
  background: #404040;
}

.slider-wrapper .slide-button#prev-slide {
  left: -25px;
  display: none;
}

.slider-wrapper .slide-button#next-slide {
  right: -25px;
}

.slider-wrapper .image-list {
  display: grid;
  grid-template-columns: repeat(82, 1fr);
  gap: 18px;
  font-size: 0;
  list-style: none;
  margin-bottom: 30px;
  overflow-x: auto;
  scrollbar-width: none;
  margin-left: -3%;
  cursor: grab;
  /* background-color: red; */
}

.slider-wrapper .image-list::-webkit-scrollbar {
  display: none;
}

.slider-wrapper .image-list .image-item {
  width: 380px;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
}

.container15 .slider-scrollbar {
  height: 40px;
  width: 90%;
  display: flex;
  margin-top: 1%;
  /* background-color: orange; */
  /* background-color: red; */
}

.slider-scrollbar .scrollbar-track {
  background: #dddbdb96;
  width: 100%;
  height: 1px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  position: relative;
}

.slider-scrollbar .scrollbar-thumb {
  position: absolute;
  background: #d521a0;
  top: -2px;

  bottom: 0;
  width: 7%;
  /* height: 100%; */
  height: 5px;
  cursor: grab;
  border-radius: inherit;
  display: flex;
  align-items: center;
}

.slider-scrollbar .scrollbar-thumb:active {
  cursor: grabbing;
  height: 5px;
  top: -2px;
}

.slider-scrollbar .scrollbar-thumb::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -10px;
  bottom: -10px;
}
