@import url("https://fonts.cdnfonts.com/css/mona-sans");
@import url("https://fonts.cdnfonts.com/css/kollektif");
@import url("https://fonts.cdnfonts.com/css/sf-pro-display");
.gallery-container {
  width: 100%;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  max-width: 1440px;
}
.container-testim-motivate {
  margin-top: 150px;
  /* background-color: red; */
}
.gallery-container-contined-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.area-imgg {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* flex-direction: row; */
  /* background-color: yellow; */
  overflow: hidden;
  /* background-color: orange; */
  gap: 15px;
}
.gallery-images-single {
  /* background-color: red; */
  /* margin: 0 22px 22px; */

  width: 30%;
  overflow: hidden;
  /* background-color: yellow; */
  /* display: block; */

  /* display: flex; */
  /* flex-wrap: wrap; */
}
.gallery-images-single img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  /* width: 250px;
  height: 250px; */
  /* width: 400px; */
  transition: transform 0.3s ease-in-out;

  /* background-color: red; */
  border-radius: 10px;
}
.gallery-images-single img:hover {
  transform: scale(1.2);
}
.button-gallery-contained {
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-gallery-contained button {
  background-color: #d521a0;
  border-radius: 25px;
  width: 145px;
  height: 51px;
  color: white;
  line-height: 22px;
  font-size: 18px;
  font-weight: 500;
  border: none;
  outline: none;
  font-family: "SF Pro Display", sans-serif;
}

.gallery-set {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  /* background-color: green; */
}
.gallery-heading {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  width: 100%;
}
.gallery-heading h2 {
  font-family: "SF Pro Display", sans-serif;
  font-size: 55px;
  font-weight: 600;
  line-height: 80px;
}
.button-gallery-contained {
  margin-top: 1.5%;
}
