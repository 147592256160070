@media screen and (max-width: 1379px) {
  .process-second-section-block-content {
    width: 94%;
    /* background-color: yellow; */
  }
  .process-second-section-block-content-process {
    width: 94%;
    /* background-color: yellow; */
  }
}
@media screen and (min-width: 1036px) and (max-width: 1046px) {
  .process-second-section-block-content-process p {
    line-height: 20px !important;
  }
}
@media screen and (min-width: 967px) and (max-width: 976px) {
  .process-second-section-block-content-process p {
    line-height: 18px !important;
  }
}
@media screen and (max-width: 1298px) {
  .process-first-section-heading {
    width: 100%;
  }

  .process-first-section-content {
    width: 94%;
    /* background-color: red; */
  }
  .process-first-section-content-pro {
    width: 94%;
    /* background-color: red; */
  }
}
@media screen and (min-width: 967px) and (max-width: 1021px) {
  .process-first-section-heading-dental h3 {
    font-size: 35px;
  }
  .process-first-section-heading-dental-pro h3 {
    font-size: 35px;
  }
  .process-first-section-heading-dental-process h3 {
    font-size: 35px;
  }
  .process-first-section-heading-dental-white h3 {
    font-size: 35px;
  }
}
@media screen and (min-width: 1022px) and (max-width: 1148px) {
  .process-first-section-heading-dental-process h3 {
    font-size: 35px !important;
  }
}
@media screen and (min-width: 1022px) and (max-width: 1148px) {
  .process-first-section-heading-dental-pro h3 {
    font-size: 35px !important;
  }
}
@media screen and (min-width: 1022px) and (max-width: 1107px) {
  .process-first-section-heading-dental-white h3 {
    font-size: 35px !important;
  }
}
@media screen and (min-width: 967px) and (max-width: 969px) {
  .process-first-section-heading-dental-white h3 {
    font-size: 34px !important;
  }
}
@media screen and (min-width: 967px) and (max-width: 1005px) {
  .process-first-section-heading-dental-process h3 {
    font-size: 33px !important;
  }
}
@media screen and (min-width: 967px) and (max-width: 1005px) {
  .process-first-section-heading-dental-pro h3 {
    font-size: 33px !important;
  }
}
@media screen and (max-width: 1277px) {
  .process-second-section-block-content p {
    font-size: 15px;
    line-height: 22px;
  }
  .process-second-section-block-content-process p {
    font-size: 15px;
    line-height: 22px;
  }
  .process-second-section-block-content1 p {
    font-size: 15px;
    line-height: 22px;
  }

  .process-second-section-block-content2 p {
    font-size: 15px;
    line-height: 22px;
  }
}
@media screen and (max-width: 1100px) {
  .process-second-section-block-heading {
    margin-left: 0%;
  }
  .process-second-section-block-heading h4 {
    font-size: 19px;
  }
}
@media screen and (max-width: 1035px) {
  .process-second-section-block-content p {
    font-size: 14px;
    line-height: 22px;
  }
  .process-second-section-block-content-process p {
    font-size: 14px;
    line-height: 22px;
  }
  .process-second-section-block-content1 p {
    font-size: 14px;
    line-height: 22px;
  }

  .process-second-section-block-content2 p {
    font-size: 14px;
    line-height: 22px;
  }
}
@media screen and (min-width: 983px) and (max-width: 990px) {
  .process-first-section-content-pro p {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 982px) {
  .process-first-section-content p {
    font-size: 15px;
  }
  .process-first-section-content-pro p {
    font-size: 15px;
  }
  .process-first-sectiion-button button {
    width: 350px;
  }
}

@media screen and (max-width: 1076px) {
  .process-main-inside {
    flex-direction: column;
  }
  .process-first-section {
    width: 100%;
    text-align: center;
  }
  .process-second-section {
    width: 102%;
  }
  .process-second-section-block {
    margin-left: 0%;
    margin-right: 2%;
  }

  .process-first-section-heading {
    margin-top: 1%;
    margin-left: 0%;
    /* background-color: red; */
  }
  .process-first-section-heading-dental {
    margin-top: 1%;
    margin-left: 0%;
    /* background-color: red; */
  }
  .process-first-section-heading-dental-pro {
    margin-top: 1%;
    margin-left: 0%;
    /* background-color: red; */
  }
  .process-first-section-heading-dental-process {
    margin-top: 1%;
    margin-left: 0%;
    /* background-color: red; */
  }
  .process-first-section-heading-dental-white {
    margin-top: 1%;
    margin-left: 0%;
    /* background-color: red; */
  }
  .process-first-section-content {
    padding-top: 0%;
    margin-bottom: 1%;
    margin-left: 0%;
    /* background-color: red; */
  }
  .process-first-section-content-pro {
    padding-top: 0%;
    margin-bottom: 1%;
    margin-left: 0%;
    /* background-color: red; */
  }
  .process-first-sectiion-button {
    margin-left: 0%;
    padding-bottom: 2%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .process-para-contained {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .process-first-sectiion-button button {
    width: 350px;
    height: 50px;
  }
  .process-second-section {
    justify-content: center;
  }
  .process-second-section-block-container {
    justify-content: space-around;
  }
  .process-second-section-block-container {
    margin-right: 0%;
    margin-top: 3%;
  }
  .process-main {
    margin-top: 4%;
  }
  .process-main-dental {
    margin-top: 4%;
  }
  .p-remove {
    display: none;
  }
  .dental-flex-hhh {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .process-section-none {
    display: none;
  }
}
@media screen and (max-width: 700px) {
  .process-first-sectiion-button {
    padding-bottom: 3%;
  }
}
@media screen and (max-width: 500px) {
  .process-first-section-heading h3 {
    font-size: 35px;
  }
  .process-first-section-heading-dental h3 {
    font-size: 35px;
  }
  .process-first-section-heading-dental-pro h3 {
    font-size: 35px;
  }
  .process-first-section-heading-dental-process h3 {
    font-size: 35px;
  }
  .process-first-section-heading-dental-white h3 {
    font-size: 35px;
  }
}
@media screen and (max-width: 416px) {
  .process-first-section-heading h3 {
    font-size: 32px;
  }
  .process-first-section-heading-dental h3 {
    font-size: 32px;
  }
  .process-first-section-heading-dental-pro h3 {
    font-size: 32px;
  }
  .process-first-section-heading-dental-process h3 {
    font-size: 32px;
  }
  .process-first-section-heading-dental-white h3 {
    font-size: 32px;
  }
}
@media screen and (max-width: 378px) {
  .process-first-section-heading h3 {
    font-size: 30px;
  }
  .process-first-section-heading-dental h3 {
    font-size: 30px;
  }
  .process-first-section-heading-dental-pro h3 {
    font-size: 30px;
  }
  .process-first-section-heading-dental-process h3 {
    font-size: 30px;
  }
  .process-first-section-heading-dental-white h3 {
    font-size: 30px;
  }
}
@media screen and (max-width: 350px) {
  .process-first-section-heading h3 {
    font-size: 28px;
  }
  .process-first-section-heading-dental h3 {
    font-size: 28px;
  }
  .process-first-section-heading-dental-pro h3 {
    font-size: 28px;
  }
  .process-first-section-heading-dental-process h3 {
    font-size: 28px;
  }
  .process-first-section-heading-dental-white h3 {
    font-size: 28px;
  }
}
@media screen and (max-width: 450px) {
  .process-first-section-content p {
    font-size: 14px;
  }
  .process-first-section-content-pro p {
    font-size: 14px;
  }
}
@media screen and (max-width: 400px) {
  .process-first-sectiion-button {
    padding-bottom: 5%;
  }
}
@media screen and (max-width: 426px) {
  .process-first-sectiion-button button {
    width: 300px;
  }
}
@media screen and (max-width: 366px) {
  .process-first-sectiion-button button {
    width: 270px;
  }
}
@media screen and (max-width: 340px) {
  .process-first-sectiion-button button {
    width: 250px;
  }
}
@media screen and (max-width: 600px) {
  .process-second-section-block-container {
    display: flex;
    flex-direction: column;
    /* background-color: red; */
  }
  .process-second-section-block {
    /* width: 49%; */
    /* width: 298px; */
    width: 98%;
  }
}
@media screen and (max-width: 676px) {
  .process-first-section-heading-dental h3 {
    font-size: 30px;
    line-height: 40px;
  }
  .process-first-section-heading-dental-pro h3 {
    font-size: 30px;
    line-height: 40px;
  }
  .process-first-section-heading-dental-process h3 {
    font-size: 30px;
    line-height: 40px;
  }
  .process-first-section-heading-dental-white h3 {
    font-size: 30px;
    line-height: 40px;
  }
}
@media screen and (max-width: 436px) {
  .process-first-section-heading-dental h3 {
    font-size: 28px;
    line-height: 35px;
  }
  .process-first-section-heading-dental-pro h3 {
    font-size: 28px;
    line-height: 35px;
  }
  .process-first-section-heading-dental-process h3 {
    font-size: 28px;
    line-height: 35px;
  }
  .process-first-section-heading-dental-white h3 {
    font-size: 28px;
    line-height: 35px;
  }
}
@media screen and (max-width: 353px) {
  .process-first-section-heading-dental h3 {
    font-size: 25px;

    line-height: 35px;
  }
  .process-first-section-heading-dental-pro h3 {
    font-size: 25px;

    line-height: 35px;
  }
  .process-first-section-heading-dental-process h3 {
    font-size: 25px;
    line-height: 35px;
  }
  .process-first-section-heading-dental-white h3 {
    font-size: 25px;
    line-height: 35px;
  }
}
@media screen and (min-width: 600px) and (max-width: 1076px) {
  .process-second-section-block-content-container {
    margin-top: 3%;
  }
}
@media screen and (max-width: 1350px) {
  .wrinkle-flex-heading h4 {
    letter-spacing: -0.5px;
  }
}
@media screen and (max-width: 1280px) {
  .wrinkle-flex-heading h4 {
    letter-spacing: -0.9px;
  }
}
@media screen and (max-width: 1230px) {
  .wrinkle-flex-heading {
    width: 80%;
  }
}
@media screen and (max-width: 1254px) {
  .fa-hmm-heading h2 {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 529px) {
  .fa-hmm-heading h2 {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 441px) {
  .fa-hmm-heading h2 {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 352px) {
  .fa-hmm-heading h2 {
    font-size: 18px !important;
  }
}
@media screen and (min-width: 1077px) {
  .process-flex-hmm-ok {
    padding-bottom: 4% !important;
  }
}
@media screen and (min-width: 1077px) {
  .process-flex-hmm-ok {
    padding-bottom: 4% !important;
  }
}
.procelien-heading-setting p {
  margin-bottom: 4%;
}
@media screen and (max-width: 1380px) {
  .procelian-enligh-setting {
    margin-bottom: 2%;
  }
}
@media screen and (max-width: 1218px) {
  .procelian-enligh-setting {
    margin-bottom: 0%;
    margin-top: -1%;
  }
}
@media screen and (max-width: 958px) {
  .procelian-enligh-setting {
    margin-bottom: -10%;
  }
}
@media screen and (max-width: 914px) {
  .procelien-heading-setting p {
    margin-bottom: -2% !important;
  }
}
@media screen and (min-width: 986px) and (max-width: 1020px) {
  .restorative-head-set {
    letter-spacing: -0.5px;
  }
}
@media screen and (min-width: 895px) and (max-width: 926px) {
  .restorative-head-set {
    letter-spacing: -0.9px;
  }
}
@media screen and (min-width: 805px) and (max-width: 832px) {
  .restorative-head-set {
    white-space: nowrap;
  }
}
@media screen and (min-width: 744px) and (max-width: 750px) {
  .restorative-head-set {
    white-space: nowrap;
  }
}
@media screen and (min-width: 697px) and (max-width: 720px) {
  .restorative-head-set {
    white-space: nowrap;
  }
}
.root-canal-heading p {
  margin-bottom: 7%;
}

@media screen and (max-width: 1400px) {
  .root-canal-heading p {
    margin-bottom: 4%;
  }
}
@media screen and (max-width: 1316px) {
  .root-canal-heading p {
    margin-bottom: 0%;
  }
}
@media screen and (max-width: 1263px) {
  .root-canal-heading p {
    margin-bottom: 5%;
  }
}
@media screen and (max-width: 1224px) {
  .root-canal-heading p {
    margin-bottom: 0%;
  }
}
@media screen and (max-width: 1148px) {
  .root-canal-heading p {
    margin-bottom: 6%;
  }
}
@media screen and (max-width: 1142px) {
  .root-canal-heading p {
    margin-bottom: 12%;
  }
}
@media screen and (max-width: 1100px) {
  .root-canal-heading p {
    margin-bottom: 7%;
  }
}
@media screen and (max-width: 786px) {
  .root-canal-heading p {
    margin-bottom: -2%;
  }
}
@media screen and (max-width: 765px) {
  .root-canal-heading p {
    margin-bottom: 5%;
  }
}
