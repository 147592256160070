.dental-container {
  width: 100vw;
}
.dental-image {
  width: 100%;
  height: 100%;
}
.dental-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
