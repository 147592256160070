@import url("https://fonts.cdnfonts.com/css/mona-sans");
@import url("https://fonts.cdnfonts.com/css/kollektif");
@import url("https://fonts.cdnfonts.com/css/sf-pro-display");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.treatcontainer-merging {
  margin-top: 200px;
}
.treatcontainer-merging-ok {
  margin-top: 220px;
}
.trest-full-container {
  max-width: 1440px;
  width: 100%;
  display: flex;
  justify-content: center;
  /* background-color: yellow; */
}
.treat-container {
  width: 100%;
  display: flex;
  /* background-color: orange; */
  justify-content: center;
  height: 100%;
}

.treat-img {
  z-index: -1;
  position: absolute;
  width: 100%;
}
.treat-img img {
  width: 100%;
  object-fit: cover;

  opacity: 10%;
}
.treat-left {
  width: 70%;
  display: flex;
  /* background-color: orange; */
}

.left-heading {
  width: 100%;
  /* background-color: red; */
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 14%;
  gap: 20px;
}
.heading-control {
  /* background-color: green; */
  width: 85%;
  margin-left: 18%;
}

.left-heading h2 {
  font-weight: 700;
  font-family: "Mona Sans", sans-serif;
  font-size: 60px;
  line-height: 55px;
  color: #000000;
}
.treat-para {
  margin-left: 18%;
  /* background-color: yellow; */
  width: 85%;
}
.treat-para p {
  font-family: "SF Pro Display", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
  word-spacing: -1px;
}
.left-img {
  width: 100%;
  height: 100%;
  /* background-color: red; */
  margin-top: -5%;
}
.left-img img {
  object-fit: contain;
  width: 100;
}
.left-button {
  width: 100%;
  margin-left: 18%;
}
.left-button button {
  width: 153px;
  height: 51px;
  border-radius: 25px;
  border: none;
  outline: none;
  background-color: #d521a0;
  cursor: pointer;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}
.left-img img {
  object-fit: cover;
  width: 100%;
  /* background-color: yellow; */
  margin-top: -4%;
  z-index: 1;
}

.right-content-container {
  width: 100%;
  /* background-color: red; */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 5%;
  margin-top: 32%;
  /* margin-left: -4%; */
}
.right-heading {
  width: 100%;
  /* background-color: green; */
}
.right-heading h2 {
  font-family: "SF Pro Display", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 17px;
  letter-spacing: 0.9px;
  color: #52525b;
}
.right-icon-container {
  display: flex;
  /* background-color: red; */
  width: 100%;
  gap: 4%;
}
.treat-right {
  width: 32%;
  display: flex;

  /* background-color: yellow; */
}
.re {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d521a0;
  width: 42px;
  height: 42px;
  border-radius: 5px;
}
.re img {
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.le {
  width: 75%;
  /* background-color: red; */
}
.le1 {
  width: 80%;
  /* background-color: red; */
}
.le-heading {
  font-family: "SF Pro Display", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #18181b;
}
.treat-icon {
  position: absolute;
  z-index: 1;
}
@media screen and (min-width: 1356px) and (max-width: 1382px) {
}
.heading-control {
  width: 90%;
}
@media screen and (min-width: 1345px) and (max-width: 1355px) {
  .heading-control {
    width: 90%;
  }
}

@media screen and (max-width: 1185px) {
  .le-heading {
    font-size: 16px;
  }
}
@media screen and (min-width: 1247px) and (max-width: 1300px) {
  .heading-control {
    width: 95% !important;
    /* background-color: red; */
  }
}
@media screen and (min-width: 1134px) and (max-width: 1180px) {
  .heading-control {
    width: 90% !important;
  }
}
@media screen and (min-width: 1021px) and (max-width: 1085px) {
  .heading-control {
    width: 85% !important;
  }
}
@media screen and (max-width: 1410px) {
  .treat-para {
    width: 80%;
  }
}
@media screen and (max-width: 1344px) {
  .heading-control {
    width: 100%;
    /* background-color: red; */
  }
}
@media screen and (max-width: 1300px) {
  .left-heading h2 {
    font-size: 55px;
    margin-top: 8%;
  }
}
@media screen and (max-width: 1180px) {
  .left-heading h2 {
    font-size: 50px;
    margin-top: 10%;
  }
}
@media screen and (max-width: 1085px) {
  .left-heading h2 {
    font-size: 45px;
  }
}

@media screen and (max-width: 1054px) {
  .le1 {
    width: 85%;
  }
}
@media screen and (max-width: 1054px) {
  .le-heading {
    font-size: 15px;
  }
}
/* @media screen and (max-width: 1075px) {
  .left-heading h2 {
    font-size: 50px;
  }
} */
@media screen and (min-width: 1441px) {
  .treat-img {
    height: 718px;
  }
  .treat-img img {
    height: 100%;
  }
}
@media screen and (max-width: 991px) {
  .treat-container {
    display: none;
  }
}
@media screen and (min-width: 992px) {
  .treat-container-responsive {
    display: none !important;
  }
}
.treat-container-responsive {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* background-color: green; */
}
.treat-img1 {
  z-index: -1;
  position: absolute;
  width: 100%;
  /* height: 100%; */
}
.treat-img1 img {
  width: 100%;
  /* height: auto; */
  /* height: 600px; */
  object-fit: cover;
  opacity: 10%;
  /* margin-top: 18%; */
  /* object-position: 85%; */
}
.left-img1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.left-img1 img {
  object-fit: contain;
  width: 100%;
  height: 500px;
  margin-top: -8%;
}
.image-container-responsive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  /* height: 100%; */
}
.responsive-content-container {
  width: 100%;
  display: flex;
  /* background-color: orange; */
  padding-top: 3%;
  padding-bottom: 3%;
}
.responive-left {
  width: 50%;
  /* background-color: yellow; */
  display: flex;
  justify-content: center;
}
.responive-right {
  width: 50%;
}
.left-responsive-content-contained {
  width: 85%;
  /* background-color: red; */
}
.left-heading1 {
  width: 100%;
  /* background-color: red; */
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  /* margin-bottom: 20%; */
}
.heading-control1 {
  /* background-color: green; */
  width: 100%;
}
.left-heading1 h2 {
  font-weight: 700;
  font-family: "Mona Sans", sans-serif;
  font-size: 35px;
  line-height: 40px;
  color: #000000;
}
.treat-para1 {
  /* background-color: pink; */
  width: 100%;
}
.treat-para1 p {
  font-family: "SF Pro Display", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
  word-spacing: -1px;
}
.left-button1 {
  width: 100%;
}
.left-button1 button {
  width: 153px;
  height: 51px;
  border-radius: 25px;
  border: none;
  outline: none;
  background-color: #d521a0;
  cursor: pointer;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}
.right-content-container1 {
  width: 100%;
  /* background-color: red; */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 5%;
}
.right-heading1 {
  width: 100%;
  padding-bottom: 4%;
  /* background-color: green; */
}
.right-heading1 h2 {
  font-family: "SF Pro Display", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 17px;
  letter-spacing: 0.9px;
  color: #52525b;
}
.right-icon-container1 {
  display: flex;
  /* background-color: red; */
  width: 100%;
  gap: 4%;
  padding-bottom: 4%;
}
.re1 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d521a0;
  width: 42px;
  height: 42px;
  border-radius: 5px;
}
.re1 img {
  object-fit: cover;
  width: 24px;
  height: 24px;
}
.le2 {
  width: 75%;
  /* background-color: red; */
}
.le3 {
  width: 80%;
  /* background-color: red; */
}
.le-heading1 {
  font-family: "SF Pro Display", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #18181b;
}
.treat-right-responisve {
  width: 90%;
  /* height: 100%; */
  /* background-color: red; */
  display: flex;
  align-items: start;
  margin-left: 7%;
  padding-top: 3%;
}
@media screen and (max-width: 855px) {
  .left-heading1 h2 {
    font-size: 30px;
  }
}
@media screen and (max-width: 733px) {
  .left-heading1 h2 {
    font-size: 25px;
  }
  .treat-para1 p {
    font-size: 14px;
  }
}
@media screen and (max-width: 611px) {
  .left-heading1 h2 {
    font-size: 24px;
  }
}
@media screen and (max-width: 790px) {
  .le-heading1 {
    font-size: 16px;
  }
}
@media screen and (max-width: 790px) {
  .le-heading1 {
    font-size: 14px;
  }
}
@media screen and (max-width: 614px) {
  .le-heading1 {
    font-size: 13px;
  }
}
@media screen and (max-width: 600px) {
  .responsive-content-container {
    display: flex;
    flex-direction: column;
  }
  .responive-left {
    width: 100%;
  }
  .responive-right {
    width: 100%;
  }
  .responive-left {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .responive-right {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .treat-right-responisve {
    margin-left: 0%;
  }
  .left-heading1 {
    gap: 0px;
  }
  .right-heading1 {
    margin-top: 1%;
    /* background-color: red; */
  }
  .right-icon-container1 {
    /* background-color: red; */
    width: 100%;
    text-align: left;
    margin-left: 13%;
    margin-top: -3%;
  }
}
@media screen and (max-width: 450px) {
  .right-heading1 {
    margin-top: 3%;
    /* background-color: red; */
  }
}
@media screen and (max-width: 400px) {
  .right-icon-container1 {
    /* background-color: red; */
    width: 100%;
    text-align: left;
    margin-left: 5%;
  }
}
@media screen and (max-width: 550px) {
  .left-responsive-content-contained {
    width: 94%;
  }
}
@media screen and (max-width: 497px) {
  .heading-control1 {
    width: 60%;
  }
  .left-heading1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 442px) {
  .heading-control1 {
    width: 70%;
  }
}
@media screen and (max-width: 379px) {
  .heading-control1 {
    width: 80%;
  }
}
@media screen and (max-width: 331px) {
  .heading-control1 {
    width: 85%;
  }
}
@media screen and (max-width: 800px) {
  .treat-img1 img {
    margin-top: 20%;
  }
}

@media screen and (max-width: 700px) {
  .treat-img1 {
    height: 100%;
  }
  .treat-img1 img {
    height: 600px;
    margin-top: 60%;
    object-position: 60%;
  }
}
@media screen and (max-width: 370px) {
  .treat-img1 img {
    margin-top: 80%;
  }
}
@media screen and (max-width: 991px) {
  .treat-para1 {
    display: none;
  }
  .left-button1 {
    margin-top: 20px;
  }
}
@media screen and (max-width: 800px) {
  .left-button1 {
    margin-top: 10px;
  }
}
@media screen and (max-width: 700px) {
  .left-button1 {
    margin-top: 7px;
  }
}
@media screen and (max-width: 600px) {
  .left-button1 {
    margin-top: 0px;
  }
}
@media screen and (max-width: 497px) {
  .left-button1 {
    margin-bottom: 8px;
  }
}
