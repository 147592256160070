@media only screen and (max-width: 1410px) {
  .p-para p {
    font-size: 17px;
  }
  .p-para-test {
    margin-left: 5%;
  }
  .p-para-test1 {
    margin-left: 5%;
  }
  .p-para-test2 {
    margin-left: 5%;
  }
  .slider-img {
    margin-left: 0%;
  }
  .left-section-content-test {
    margin-left: 1%;
  }
}
@media only screen and (max-width: 1333px) {
  .p-para p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 1300px) {
  .re-para-test {
    width: 100%;
    font-size: 13px;
  }
}
@media only screen and (max-width: 907px) {
  .re-heading-test {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1253px) {
  .p-para p {
    font-size: 15px;
  }
}
@media only screen and (min-width: 1058px) and (max-width: 1137px) {
  .okkkkkkkkk {
    display: none;
  }
}
@media only screen and (min-width: 919px) and (max-width: 936px) {
  .okkkkkkkkk {
    display: none;
  }
}
@media only screen and (max-width: 1059px) {
  .p-para p {
    font-size: 14px !important;
  }
}
@media only screen and (max-width: 1038px) {
  .slider-item {
    height: 470px;
  }
}
@media only screen and (max-width: 968px) {
  .slider-item {
    height: 480px;
  }
}
@media only screen and (max-width: 950px) {
  .slider-item {
    height: 420px;
  }
}
@media only screen and (max-width: 738px) {
  .slider-item {
    height: 350px;
  }
}
@media only screen and (max-width: 534px) {
  .slider-item {
    height: 370px;
  }
}
@media only screen and (max-width: 498px) {
  .slider-item {
    height: 390px;
  }
  .gradiant1 {
    height: 420px;
  }
}
@media only screen and (max-width: 450px) {
  .slider-item {
    height: 410px;
  }
  .gradiant1 {
    height: 430px;
  }
}
@media only screen and (max-width: 402px) {
  .slider-item {
    height: 430px;
  }
  .gradiant1 {
    height: 450px;
  }
}
@media only screen and (max-width: 378px) {
  .slider-item {
    height: 450px;
  }
  .gradiant1 {
    height: 470px;
  }
}
@media only screen and (max-width: 378px) {
  .slider-item {
    height: 480px;
  }
  .gradiant1 {
    height: 500px;
  }
}
/* @media only screen and (max-width: 920px) {
  .slider-item {
    height: 340px;
  }
} */
@media only screen and (max-width: 1083px) {
  .p-para-test {
    width: 90%;
  }
}
@media only screen and (max-width: 851px) {
  .p-para p {
    font-size: 18px;
  }
  .re-heading-test {
    font-size: 16px;
  }
}
@media only screen and (max-width: 739px) {
  .p-para p {
    font-size: 17px;
  }
  .re-heading-test {
    font-size: 16px;
  }
}
@media only screen and (max-width: 697px) {
  .p-para p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 656px) {
  .p-para p {
    font-size: 15px;
  }
}

@media only screen and (max-width: 614px) {
  .p-para p {
    font-size: 18px;
  }
  .re-heading-test {
    font-size: 16px;
  }
}
@media only screen and (max-width: 998px) {
  .testimonial-container {
    margin-top: 5%;
  }
  .testi-heading h2 {
    /* font-size: 18px; */
    line-height: 30px;
  }
  .testi-para h2 {
    /* font-size: 40px; */
    line-height: 40px;
  }
  .testimon-para {
    width: 50%;
  }
  .testimon-para p {
    font-size: 18px;
    line-height: 25px;
  }
  /* .custom-arrow {
    margin-top: 9%;
  } */
}
@media only screen and (max-width: 564px) {
  .custom-arrow {
    margin-top: 10%;
  }
}
@media only screen and (max-width: 862px) {
  .custom-arrow {
    margin-top: 5%;
  }
}
@media only screen and (max-width: 738px) {
  .custom-arrow {
    margin-top: 9%;
  }
}
@media only screen and (max-width: 402px) {
  .testimonial-container {
    margin-bottom: 15%;
  }
}
@media only screen and (max-width: 378px) {
  .testimonial-container {
    margin-bottom: 21%;
  }
}
@media only screen and (max-width: 794px) {
  .testimon-para {
    width: 60%;
  }
}
@media only screen and (max-width: 661px) {
  .testimon-para {
    width: 70%;
  }
}
@media only screen and (max-width: 567px) {
  .testimon-para {
    width: 80%;
  }
}
@media only screen and (max-width: 496px) {
  .testimon-para {
    width: 90%;
  }
}
@media only screen and (max-width: 441px) {
  .testi-para h2 {
    font-size: 35px;
  }
  .testimon-para p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 393px) {
  .testi-para h2 {
    font-size: 30px;
  }
  .testimon-para p {
    font-size: 15px;
  }
}
@media only screen and (max-width: 1350px) {
  .custom-prev {
    right: 43%;
  }

  .custom-next {
    left: 43%;
  }
}
@media only screen and (max-width: 1250px) {
  .custom-prev {
    right: 42%;
  }

  .custom-next {
    left: 42%;
  }
}
@media only screen and (max-width: 1100px) {
  .custom-prev {
    right: 41%;
  }

  .custom-next {
    left: 41%;
  }
}
@media only screen and (max-width: 990px) {
  .custom-prev {
    right: 40%;
  }

  .custom-next {
    left: 40%;
  }
}

@media only screen and (max-width: 880px) {
  .custom-prev {
    right: 39%;
  }

  .custom-next {
    left: 39%;
  }
}
@media only screen and (max-width: 790px) {
  .custom-prev {
    right: 38%;
  }

  .custom-next {
    left: 38%;
  }
}
@media only screen and (max-width: 725px) {
  .custom-prev {
    right: 37%;
  }

  .custom-next {
    left: 37%;
  }
}
@media only screen and (max-width: 660px) {
  .custom-prev {
    right: 35%;
  }

  .custom-next {
    left: 35%;
  }
}
@media only screen and (max-width: 590px) {
  .custom-prev {
    right: 33%;
  }

  .custom-next {
    left: 33%;
  }
}
@media only screen and (max-width: 508px) {
  .custom-prev {
    right: 30%;
  }

  .custom-next {
    left: 30%;
  }
}
@media only screen and (max-width: 434px) {
  .custom-prev {
    right: 27%;
  }

  .custom-next {
    left: 27%;
  }
}
@media only screen and (max-width: 372px) {
  .custom-prev {
    right: 22%;
  }

  .custom-next {
    left: 22%;
  }
}
@media only screen and (max-width: 369px) {
  .p-para p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 328px) {
  .p-para p {
    font-size: 15px;
  }
}
@media only screen and (max-width: 367px) {
  .testimonial-container {
    height: 700px;
  }
  .custom-arrow {
    margin-top: 12%;
  }
}
@media only screen and (min-width: 1441px) {
  .slider-flex {
    width: 100%;
    max-width: 1440px;
  }
}
@media only screen and (min-width: 1600px) {
  .custom-prev {
    right: 45%;
  }

  .custom-next {
    left: 45%;
  }
}
