@media only screen and (max-width: 1000px) {
  .gallery-images-single {
    width: 45%;
  }
}
@media only screen and (max-width: 500px) {
  .gallery-images-single {
    width: 90%;
  }
}
@media only screen and (max-width: 394px) {
  .gallery-heading h2 {
    font-size: 40px;
  }
}
