@import url("https://fonts.cdnfonts.com/css/mona-sans");
@import url("https://fonts.cdnfonts.com/css/kollektif");
@import url("https://fonts.cdnfonts.com/css/sf-pro-display");
@media screen and (max-width: 1400px) {
  .lusia-contained-offer {
    width: 75% !important;
    /* background-color: yellow; */
  }
  .lusia-left-offer {
    align-items: center !important;
  }
  .lusia-left-offer1 {
    align-items: center !important;
  }
}
@media screen and (max-width: 1362px) {
  .lusia-para-offer p {
    font-size: 32px !important;
  }
}
@media screen and (max-width: 1276px) {
  .lusia-para-offer p {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 1190px) {
  .lusia-para-offer p {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 1103px) {
  .lusia-para-offer p {
    font-size: 26px !important;
  }
}
@media screen and (max-width: 793px) {
  .lusia-para-offer p {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 726px) {
  .lusia-para-offer p {
    font-size: 22px !important;
  }
}
@media screen and (max-width: 605px) {
  .lusia-container-full-contained-offer {
    flex-direction: column;
  }
  .lusia-right-offer {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .lusia-right-offer1 {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-bottom: -10%;
  }
  .lusia-left-offer {
    width: 100% !important;
  }
  .lusia-left-offer1 {
    width: 100% !important;
  }
  .lusia-contained-offer {
    width: 85% !important;
    /* background-color: yellow; */
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;

    text-align: center;
  }
  .lusia-right-contained-offer {
    width: 85% !important;
    /* background-color: orange; */
    text-align: center;
  }
  .lusia-right-contained-offer1 {
    width: 85% !important;
    /* background-color: orange; */
    text-align: center;
  }
  .expert-img10 {
    justify-content: center;
  }

  .right-para-offer {
    width: 100%;
    margin-top: 0% !important;
  }
  .expert-heading-control-offer-oral {
    font-size: 22px !important;
  }

  .expert-img-oral {
    display: flex !important;
    justify-content: center !important;
    /* background-color: yellow; */
    text-align: center !important;
    align-items: center !important;
  }
  .set-tive {
    margin-bottom: 5%;
  }
}

@media screen and (max-width: 437px) {
  .lusia-para-offer p {
    font-size: 20px !important;
    line-height: 30px !important;
  }
}
@media screen and (max-width: 393px) {
  .lusia-para-offer p {
    font-size: 18px !important;
    line-height: 30px !important;
  }
}
@media screen and (max-width: 1190px) {
  .lusia-para-offer-oral p {
    font-size: 22px;
    line-height: 30px;
  }
}
@media screen and (max-width: 1053px) {
  .lusia-para-offer-oral p {
    font-size: 20px;
    line-height: 30px;
  }
}
@media screen and (max-width: 946px) {
  .lusia-para-offer-oral p {
    font-size: 18px;
    line-height: 30px;
  }
}
@media screen and (max-width: 750px) {
  .lusia-para-offer-oral p {
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
  }
}
@media screen and (max-width: 840px) {
  .lusia-right-offer1 {
    padding-bottom: 75px;
  }
}
@media screen and (max-width: 757px) {
  .lusia-right-offer1 {
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 808px) {
  .lusia-right-offer1 {
    padding-bottom: 43px;
  }
}
@media screen and (max-width: 765px) {
  .lusia-right-offer1 {
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 670px) {
  .lusia-right-offer1 {
    padding-bottom: 45px;
  }
}
@media screen and (max-width: 642px) {
  .lusia-right-offer1 {
    padding-bottom: 60px;
  }
}
@media screen and (max-width: 794px) {
  .expert-heading-control-offer-oral {
    font-size: 18px;
  }
}
@media screen and (max-width: 722px) {
  .expert-heading-control-offer-oral {
    font-size: 16px;
  }
}
@media screen and (max-width: 847px) {
  .expert-heading-control-offer-oral {
    font-size: 18px;
  }
}
@media screen and (max-width: 770px) {
  .expert-heading-control-offer-oral {
    font-size: 17px;
  }
}
@media screen and (max-width: 731px) {
  .expert-heading-control-offer-oral {
    font-size: 16px;
  }
}
@media screen and (max-width: 693px) {
  .expert-heading-control-offer-oral {
    font-size: 15px;
  }
}
@media screen and (max-width: 655px) {
  .expert-heading-control-offer-oral {
    font-size: 14px;
  }
}
@media screen and (max-width: 617px) {
  .expert-heading-control-offer-oral {
    font-size: 13px;
  }
}
@media screen and (max-width: 750px) {
  .lusia-contained-offer {
    width: 72% !important;
  }
  .expert-heading-control-offer-oral {
    white-space: nowrap;
  }
}
@media screen and (max-width: 400px) {
  .expert-heading-control-offer-oral {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 340px) {
  .expert-heading-control-offer-oral {
    font-size: 16px !important;
  }
}

/* RESPONSIVE CODE FOR "EnlightOral" */
@media screen and (max-width: 1100px) {
  .lusia-para-offer-oral-enlight p {
    font-size: 20px;
  }
}
@media screen and (max-width: 782px) {
  .lusia-para-offer-oral-enlight p {
    font-size: 18px;
  }
}
@media screen and (max-width: 985px) {
  .expert-heading-control-offer-oral-enlight {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 894px) {
  .expert-heading-control-offer-oral-enlight {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 804px) {
  .expert-heading-control-offer-oral-enlight {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 743px) {
  .expert-heading-control-offer-oral-enlight {
    font-size: 13px !important;
  }
}
@media screen and (max-width: 743px) {
  .lusia-para-offer-oral-enlight p {
    font-size: 16px;
    line-height: 25px;
  }
}
@media screen and (max-width: 696px) {
  .expert-heading-control-offer-oral-enlight {
    white-space: nowrap;
  }
}
@media screen and (max-width: 605px) {
  .expert-heading-control-offer-oral-enlight {
    font-size: 20px !important;
  }
  .lusia-para-offer-oral-enlight {
    width: 100% !important;
  }
  .lusia-para-offer-oral-enlight p {
    font-size: 17px;
    line-height: 25px;
  }
}
@media screen and (max-width: 410px) {
  .expert-heading-control-offer-oral-enlight {
    font-size: 18px !important;
  }

  .lusia-para-offer-oral-enlight p {
    font-size: 16px;
    line-height: 25px;
  }
}
@media screen and (max-width: 369px) {
  .expert-heading-control-offer-oral-enlight {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 334px) {
  .expert-heading-control-offer-oral-enlight {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 1400px) {
  .connnnnnn {
    margin-bottom: 6%;
  }
}
@media screen and (max-width: 1316px) {
  .connnnnnn {
    margin-bottom: 10%;
  }
}
@media screen and (max-width: 1148px) {
  .connnnnnn {
    margin-bottom: 6%;
  }
}
@media screen and (max-width: 1026px) {
  .connnnnnn {
    margin-bottom: 15%;
  }
}
@media screen and (max-width: 893px) {
  .connnnnnn {
    margin-bottom: 10%;
  }
}

@media screen and (max-width: 841px) {
  .connnnnnn {
    margin-bottom: 15%;
  }
}
@media screen and (max-width: 840px) {
  .connnnnnn {
    margin-bottom: 18%;
  }
}
@media screen and (max-width: 808px) {
  .connnnnnn {
    margin-bottom: 14%;
  }
}
