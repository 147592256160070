.about-flex-container1 {
  background-color: rgba(231, 235, 236, 0.288);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -2%;
}
.about-para-invis {
  width: 100%;
}
.about-para-invis p {
  font-family: "SF Pro Display", sans-serif;
  font-size: 19px;
  line-height: 22px;
  font-weight: 500;
  padding-top: 5%;
  padding-bottom: 4%;
}
.about-heading-invis {
  width: 75%;
  /* background-color: red; */
}
.about-heading-invis h2 {
  font-family: "Mona Sans", sans-serif;
  font-size: 56px;
  line-height: 62px;
  font-weight: 600;
  /* background-color: green; */
}
.about-heading-invis-dental {
  width: 90%;
  /* background-color: red; */
}
.about-heading-invis-dental h2 {
  font-family: "Mona Sans", sans-serif;
  font-size: 56px;
  line-height: 55px;
  font-weight: 600;
  /* background-color: green; */
}
.about-heading-invis-dental-enlight {
  width: 100%;
  /* background-color: red; */
}
/* .about-heading-invis-dental-set {
  width: 110%;
} */
.about-heading-invis-dental-enlight h2 {
  font-family: "Mona Sans", sans-serif;
  font-size: 56px !important;
  line-height: 55px;
  font-weight: 600;
  /* background-color: green; */
}
.about-right-img10 {
  height: 100%;
  /* z-index: -1; */
}
.about-right-img10 img {
  object-fit: contain;
  /* z-index: -1; */
}
.about-right-img10 {
  width: 100%;
  height: 100%;
  /* z-index: -1; */
}
.about-right-img10 img {
  object-fit: cover;
  object-position: -10px;
  height: 100%;
  width: 100%;
  /* z-index: -1; */
}
.about-right-img11 {
  height: 100%;
  /* z-index: -1; */
}
.about-right-img11 img {
  object-fit: contain;
  /* z-index: -1; */
}
.about-right-img11 {
  width: 100%;
  height: 100%;
  /* z-index: -1; */
}
.about-right-img11 img {
  object-fit: cover;
  object-position: -10px;
  height: 100%;
  width: 100%;
  padding-top: 30px;
  /* z-index: -1; */
}
@media only screen and (min-width: 606px) and (max-width: 794px) {
  .para-dental-flex {
    font-size: 14px !important;
  }
}
