@media screen and (max-width: 850px) {
  .dental-container {
    margin-top: -5%;
  }
}
@media screen and (max-width: 700px) {
  .dental-container {
    margin-top: -7%;
  }
}
@media screen and (max-width: 693px) {
  .dental-container {
    margin-top: -10%;
  }
}
@media screen and (max-width: 600px) {
  .dental-container {
    margin-top: 2%;
  }
}
@media screen and (max-width: 540px) {
  .dental-container {
    margin-top: 12%;
  }
}
@media screen and (max-width: 471px) {
  .dental-container {
    margin-top: 16%;
  }
}
@media screen and (max-width: 451px) {
  .dental-container {
    margin-top: 20%;
  }
}
@media screen and (max-width: 380px) {
  .dental-container {
    margin-top: 27%;
  }
}
@media screen and (max-width: 369px) {
  .dental-container {
    margin-top: 31%;
  }
}
@media screen and (max-width: 357px) {
  .dental-container {
    margin-top: 42%;
  }
}
