@import url("https://fonts.cdnfonts.com/css/mona-sans");
@import url("https://fonts.cdnfonts.com/css/kollektif");
@import url("https://fonts.cdnfonts.com/css/sf-pro-display");
.church-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
}
.church-container-church {
  display: flex;
  align-items: center;
  justify-content: center;
}
.church-main-heading {
  width: 85%;
  display: flex;
  margin-bottom: 1%;
  /* background-color: yellow; */
}
.church-main-heading h2 {
  font-family: "SF Pro Display", sans-serif;
  font-size: 35px;
  font-weight: 600;
  line-height: 40px;
}
.church-cpntainer-contained-material {
  width: 85%;
  display: flex;
  gap: 1.5%;
}
.church-left {
  width: 50%;
  /* background-color: red; */
}
.church-right {
  width: 50%;
  /* background-color: yellow; */
}
.church-left-content {
  display: flex;
  width: 100%;
  /* background-color: pink; */
  padding: 2%;
  padding-top: 4%;
  padding-bottom: 4%;
  border-radius: 10px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Customize the shadow as needed */
}
.church-img {
  width: 30%;
  /* background-color: purple; */
}
.church-img img {
  object-fit: contain;
  width: 170px;
}
.church-heading {
  /* background-color: orange; */
  display: flex;
  align-items: center;
}
.church-heading h2 {
  font-family: "SF Pro Display", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 25px;
}
.church-heading-container {
  width: 70%;
  display: flex;
  flex-direction: column;
}
.phone-set {
  width: 20px !important;
  margin-right: 2%;
}
.phone-setttt {
  margin-right: 3%;
}
.img-heading-container {
  width: 100%;
}
.img-heading-container h1 img {
  object-fit: contain;
  width: 15px;
}
.img-heading-container h1 {
  font-family: "SF Pro Display", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
}
