@import url("https://fonts.cdnfonts.com/css/mona-sans");
@import url("https://fonts.cdnfonts.com/css/kollektif");
@import url("https://fonts.cdnfonts.com/css/sf-pro-display");
.expert-right-story {
  width: 51%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-bottom: 3.5%;
}
.expert-left-story {
  width: 49%;
  /* background-color: green; */
  /* position: relative; */
}
.about-button10 {
  width: 100%;
  display: flex;
}
.about-button10 button img {
  margin-bottom: 3%;
}
.about-button10 button {
  width: 162px;
  height: 60px;
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: #d521a0;
  cursor: pointer;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
}
.expert-container-story {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  /* margin-bottom: 3%; */
  /* background-color: red; */
}
.expert-container-story1 {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0%;
  /* margin-bottom: 3%; */
  /* margin-bottom: 3%; */
  /* background-color: red; */
}
.expert-container-contained1-story {
  /* background-color: orange; */
  display: flex;
  width: 90%;
}
.expert-para-container-story {
  width: 89.1%;
  /* background-color: yellow; */
}
.expert-para-container-story p {
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: #767676;
}
.expert-para-container-story1 {
  width: 92%;
  /* background-color: yellow; */
}
.expert-para-container-story1 p {
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: #767676;
}
.expert-heading-container-story {
  width: 90%;
  /* padding-bottom: 2%; */
}
.expert-heading-container-story p {
  font-family: "SF Pro Display", sans-serif;
  font-size: 34px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 1px;
}
.expert-img-container-contained {
  width: 100%;
  /* height: 100%; */
  /* position: absolute; */
  display: flex;
  padding-bottom: 3.5%;
  box-sizing: border-box;

  /* background-color: yellow; */
}
.expert-img-container-contained img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  /* background-color: red; */
}
.expert-img-container-contained1 {
  width: 100%;
  /* height: 100%; */
  /* position: absolute; */
  display: flex;
  padding-bottom: 3.5%;
  box-sizing: border-box;

  /* background-color: yellow; */
}
.expert-img-container-contained1 img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  /* background-color: red; */
}
.expert-left-contained-story {
  width: 100%;
  position: relative;
  display: flex;
  align-items: end;
  box-sizing: border-box;
  /* background-color: red; */
}
.expert-left-contained-story img {
  object-fit: cover;
  width: 100%;
}
.story-mg-flex {
  width: 94%;
  /* position: absolute; */
  display: flex;
  gap: 20px;
}
.story-content-img-flex {
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
}
