@import url("https://fonts.cdnfonts.com/css/mona-sans");
@import url("https://fonts.cdnfonts.com/css/kollektif");
@import url("https://fonts.cdnfonts.com/css/sf-pro-display");
.about-flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.treatcontainer-merging-hgzxdjh {
  margin-top: 164px;
}
.about-full-conatiner-contained {
  max-width: 1440px;
  width: 100%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.about-container-contained {
  width: 100%;
  height: auto;
  display: flex;
  margin-top: -3%;
  /* background-color: red; */
  /* background-color: yellow; */
}
.about-container-left {
  width: 40%;
  /* background-color: yellow; */
  display: flex;
  align-items: center;
  justify-content: end;
  /* height: auto; */
}
.about-container-contained-leftt {
  /* background-color: pink; */
  width: 85%;
  margin-bottom: 7%;
}
.about-heading {
  width: 75%;
  /* background-color: red; */
}
.about-heading h2 {
  font-family: "Mona Sans", sans-serif;
  font-size: 56px;
  line-height: 62px;
  font-weight: 600;
  /* background-color: green; */
}
.about-container-right {
  width: 70%;
  /* background-color: orange; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-right-img {
  height: 100%;
  /* z-index: -1; */
}
.about-right-img img {
  object-fit: contain;
  /* z-index: -1; */
}
.about-para {
  width: 100%;
}
.about-para p {
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
  padding-top: 5%;
  padding-bottom: 4%;
}
.about-button {
  width: 100%;
  display: flex;
}
.about-button button img {
  margin-bottom: 3%;
}
.about-button button {
  width: 162px;
  height: 60px;
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: #d521a0;
  cursor: pointer;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
}
.about-container-right {
  width: 60%;
  /* background-color: orange; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-right-img {
  width: 100%;
  height: 100%;
  /* z-index: -1; */
}
.about-right-img img {
  object-fit: cover;
  object-position: -50px;
  height: 100%;
  width: 100%;
  /* z-index: -1; */
}
