@import url("https://fonts.cdnfonts.com/css/mona-sans");
@import url("https://fonts.cdnfonts.com/css/kollektif");
@import url("https://fonts.cdnfonts.com/css/sf-pro-display");
.test-container-full-contained {
  /* max-width: 1440px; */
  width: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.testi-full-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  /* background-color: green; */
}
.testimonial-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 650px;
  margin-bottom: 10%;
  margin-top: 3%;
  /* background-color: red; */
}
.testi-containerr-contained {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* background-color: purple; */
}
.testi-heading {
  width: 100%;
  /* background-color: red; */
}
.testi-heading h2 {
  font-size: 18px;
  line-height: 35px;
  color: #d521a0;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
  text-align: center;
}
.testi-para {
  width: 100%;
  /* background-color: pink; */
}
.testi-para h2 {
  color: #d521a0;
  font-size: 40px;
  line-height: 50px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 600;
  text-align: center;
  /* margin-bottom: 70px; */
}
.testimon-para {
  width: 45%;
  /* background-color: pink; */
  margin-top: 17px;
}
.testimon-para p {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  color: #18181b;
  text-align: center;
  line-height: 30px;
}
.slider-container {
  /* max-width: 1440px; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  text-align: center;
  /* margin-bottom: 3%; */
  /* box-shadow: 1px 1px 15px 2px black; */
}
.slider-item {
  background-color: #fff;
  /* background-color: lightblue; */
  height: 450px;
  border-radius: 15px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 85% !important;
  /* background-color: rgb(255, 255, 255) !important; */
  /* background-color: lightblue; */
  box-shadow: 1px 1px 15px 2px #5050501a;
  -webkit-box-shadow: 1px 1px 15px 2px #9c9c9c1a;
  -moz-box-shadow: 1px 1px 15px 2px #9c9c9c1a;
}

.h3-heading h3 {
  font-size: 20px;
  line-height: 30px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  /* background-color: yellow; */
}
.h5-heading {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
.h5-heading p {
  font-size: 18px;
  line-height: 25px;
  color: #4b5563;

  /* background-color: red; */
}

.p-para-test {
  display: flex;
  justify-content: center !important;
  width: 88%;
  /* background-color: green; */
  margin-left: 6%;
  margin-bottom: 3%;
}
.p-para-test1 {
  display: flex;
  justify-content: center !important;
  width: 90%;
  /* background-color: green; */
  margin-left: 6%;
  margin-bottom: 3%;
}
.p-para-test2 {
  display: flex;
  justify-content: center !important;
  width: 90%;
  /* background-color: green; */
  margin-left: 6%;
  margin-bottom: 3%;
}
.p-para {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  width: 100%;
  /* background-color: yellow; */
}
.p-para p {
  font-size: 16px;
  line-height: 22px;
  color: #18181b;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
.custom-arrows-contained {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* height: 100%; */
  position: relative;
  background-color: red;
}
.custom-arrow {
  color: #ae9c7f;
  background-color: #e7e5e559;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  z-index: 1;
  transition: background-color 0.3s, color 0.3s;
  width: 74px;
  height: 48px;
  font-family: "Poppins", sans-serif;
  /* margin-top: 20px; */
  font-weight: 400;
  font-size: 20px;
  margin-top: 4%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .custom-arrow.active {
  background-color: #ae9c7f;
  color: white;
} */

.custom-prev {
  position: absolute;
  right: 43%;
}

.custom-next {
  position: absolute;
  left: 44%;
}
.slider-img {
  margin-top: 4%;
  margin-bottom: 5%;
  margin-left: 1%;
}
.slider-img img {
  width: 150px;
  height: 50px;
  object-fit: cover;
}
.gradiant1 {
  height: 389px;
  width: 100%;
  /* background-color: orange; */
  z-index: -1;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.g-card1 {
  height: 100%;
  width: 60%;
  background-image: linear-gradient(
    to right,
    #ff94bd41,
    #eda3a559,
    #fcafff57,
    #b7c1fc52,
    #a2e1fe56
  );
}

.left-section-content-test {
  /* background-color: green; */
  width: 82%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2%;
}
.left-section-content-container-test {
  display: flex;
  width: 90%;
  /* background-color: yellow; */
  justify-content: center;
  align-items: center;
}
.le-test {
  width: 20%;
  /* background-color: purple; */
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  display: flex;
}

.re-test {
  width: 100%;
  /* background-color: pink; */
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 6%;
}
.re-heading-test {
  font-family: "SF Pro Display", sans-serif;
  font-weight: 600;
  width: 100%;
  color: #18181b;
  font-size: 16px;
  line-height: 28px;
  /* letter-spacing: 1.5; */
  /* background-color: orange; */
}
.re-para-test {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  width: 100%;
  font-size: 14px;
  color: #52525b;
  line-height: 20px;
  /* background-color: red; */
  /* letter-spacing: 1.5; */
  /* background-color: red; */
}
.testing-para {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  width: 100%;
  font-size: 14px;
  color: #52525b;
  line-height: 20px;
  /* background-color: red; */
  /* letter-spacing: 1.5; */
  /* background-color: red; */
}
.le-image-test {
  display: flex;
  justify-content: center;
  align-items: center;
}
.le-image-test img {
  object-fit: cover;
  width: 43px;
  height: 43px;
  border-radius: 50%;
}
.slider-flex {
  width: 100%;
  max-width: 1300px;
}
.slider-flex-main {
  width: 100%;
  display: flex;
  justify-content: center;
}
