@media screen and (max-width: 1309px) {
  .leader-heading h2 {
    font-size: 48px;
  }
  .leader-paragraph {
    margin-bottom: 0%;
  }
}
@media screen and (max-width: 1256px) {
  .leader-heading h2 {
    font-size: 46px;
  }
}
@media screen and (max-width: 1200px) {
  .leader-heading h2 {
    font-size: 44px;
  }
}
@media screen and (max-width: 1150px) {
  .leader-heading h2 {
    font-size: 42px;
  }
}
@media screen and (max-width: 1030px) {
  .leader-heading h2 {
    font-size: 38px !important;
  }
  .leader-heading-right h2 {
    font-size: 45px;
  }
  .about-button1 {
    margin-bottom: 6%;
  }
}
@media screen and (max-width: 914px) {
  .leader-heading-right h2 {
    font-size: 40px;
  }
}
@media screen and (max-width: 1100px) {
  .leader-heading h2 {
    font-size: 40px;
  }
}
@media screen and (max-width: 980px) {
  .leader-heading h2 {
    font-size: 36px !important;
  }
}
@media screen and (max-width: 930px) {
  .leader-heading h2 {
    font-size: 34px !important;
  }
}
@media screen and (max-width: 740px) {
  .leader-heading h2 {
    font-size: 30px !important;
    line-height: 38px;
  }
}
@media screen and (max-width: 850px) {
  .leader-left {
    width: 50%;
  }
  .leader-right {
    width: 50%;
  }
  .leader-heading-right h2 {
    font-size: 35px;
  }
}
@media screen and (max-width: 1005px) {
  .leader-paragraph p {
    font-size: 14px;
  }
}
@media screen and (max-width: 877px) {
  .leader-paragraph p {
    font-size: 13px;
  }
}
@media screen and (max-width: 800px) {
  .leader-paragraph p {
    font-size: 13px;
    line-height: 18px;
  }
}
@media screen and (max-width: 800px) {
  .leader-heading-right h2 {
    font-size: 30px;
  }
}
@media screen and (max-width: 680px) {
  .leader-heading-right h2 {
    font-size: 27px;
  }
}
@media screen and (max-width: 620px) {
  .leader-heading-right h2 {
    font-size: 25px;
  }
}
@media screen and (max-width: 670px) {
  .leader-heading h2 {
    font-size: 28px !important;
    line-height: 38px;
  }
}
@media screen and (max-width: 620px) {
  .leader-heading h2 {
    font-size: 26px !important;
    line-height: 38px;
  }
}
@media screen and (min-width: 651px) and (max-width: 700px) {
  .leader-left {
    height: 550px;
  }
}
@media screen and (max-width: 650px) {
  .leader-container {
    flex-direction: column;
  }
  .leader-left {
    width: 100%;
  }

  .leader-right {
    width: 100%;
  }
  .leader-left-img {
    display: none !important;
  }
  .leader-heading {
    width: 100%;
    /* background-color: yellow; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .leader-heading h2 {
    font-size: 40px !important;
    line-height: 45px;
  }
  .leader-content-container {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    /* background-color: pink; */
  }
  .leading-heading-flex {
    width: 90%;
    /* background-color: yellow; */
  }
  .leader-para-dn {
    /* background-color: green; */
    width: 95%;
  }
  .leader-paragraph {
    margin-bottom: 5%;
  }
  .leader-paragraph p {
    font-size: 20px !important;
    line-height: 25px;
  }
  .leader-heading-right h2 {
    font-size: 50px;
  }
  .leader-paragraph {
    /* background-color: yellow; */
    width: 100%;
  }
}
@media screen and (max-width: 588px) {
  .leader-heading-right h2 {
    font-size: 45px;
  }
}
@media screen and (max-width: 520px) {
  .leader-heading-right h2 {
    font-size: 40px;
  }
}
@media screen and (max-width: 480px) {
  .leader-heading-right h2 {
    font-size: 35px;
    line-height: 20px;
  }
}
@media screen and (max-width: 486px) {
  .leader-paragraph {
    margin-bottom: 3%;
  }
}
@media screen and (max-width: 490px) {
  .leader-paragraph p {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 440px) {
  .leader-paragraph p {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 550px) {
  .leader-heading {
    margin-top: 10px;
  }
}
@media screen and (max-width: 427px) {
  .leader-heading {
    margin-top: 8px;
  }
  .leader-heading h2 {
    line-height: 30px !important;
  }
}
@media screen and (max-width: 500px) {
  .leader-heading h2 {
    font-size: 30px !important;
    line-height: 40px;
  }
}
@media screen and (max-width: 460px) {
  .leader-heading h2 {
    font-size: 26px !important;
    line-height: 40px;
  }
}
@media screen and (max-width: 373px) {
  .leader-heading h2 {
    font-size: 23px !important;
    /* line-height: 40px; */
  }
}
@media screen and (min-width: 651px) {
  .leader-left-img10 {
    display: none;
  }
}
@media screen and (max-width: 400px) {
  .leader-left-img10 img {
    height: 650px;
  }
  .leader-para-dn {
    margin-bottom: 3%;
  }
  .leader-heading-right h2 {
    font-size: 30px;
    line-height: 15px;
  }
  .about-button1 {
    margin-bottom: 5%;
  }
}
@media screen and (max-width: 358px) {
  .leader-heading-right h2 {
    font-size: 25px;
    line-height: 10px;
  }
}
@media screen and (max-width: 450px) {
  .expert-content3 {
    margin-top: 5%;
  }
}
@media screen and (max-width: 391px) {
  .leader-left-img10 img {
    height: 650px;
  }
  .leader-para-dn {
    margin-bottom: 0%;
  }
}
@media screen and (min-width: 851px) and (max-width: 1146px) {
  .leader-paragraph {
    width: 90%;
  }
  .leader-paragraph p {
    letter-spacing: -0.5px;
  }
}
@media screen and (min-width: 1006px) and (max-width: 1009px) {
  .leader-paragraph p {
    letter-spacing: -0.6px;
  }
}
