@import url("https://fonts.cdnfonts.com/css/mona-sans");
@import url("https://fonts.cdnfonts.com/css/kollektif");
@import url("https://fonts.cdnfonts.com/css/sf-pro-display");
.Treatments-main {
  width: 100vw;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-top: 7%;
  padding-bottom: 4%;
}

.Treatments {
  max-width: 1440px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Treatments-second-container {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  /* background-color: yellow; */
}
.treatment-heading-flex {
  width: 100%;
}

.treatment-heading-flex h2 {
  font-size: 20px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 600;
  line-height: 20px;
}
.treatment-para-flex {
  width: 90%;
}
.treatment-para-flex p {
  font-size: 16px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
  line-height: 20px;
}
.treatment-flex-content-contained {
  width: 95%;
  /* background-color: orange; */
}
.treatment-flex-content-contained1 {
  /* position: absolute; */
  width: 95%;
  /* background-color: orange; */
}
.treeeet-flex {
  display: flex;
  justify-content: end;
  align-items: center;
}
.treeeet-flex1 {
  display: flex;
  justify-content: end;
  align-items: end;
  z-index: 1;
  position: absolute;
  width: 280px;
  height: 293px;
}
.treat-grid-img-flex {
  width: 100%;
}
.treat-grid-img-flex img {
  object-fit: contain;
}
.img-contained-treatment {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 22%;
}
.img-contained-treatment img {
  object-fit: contain;
}

.treatment-box-grid {
  background-color: #fbe9f5;
  width: 280px;
  height: 293px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  z-index: -1;
}
.treatment-box-grid1 {
  background-color: #fafce9;
  width: 280px;
  height: 293px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  z-index: -1;
}
.treatment-box-grid2 {
  background-color: #cfdff4;
  width: 280px;
  height: 293px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  z-index: -1;
}
.treatment-box-grid3 {
  background-color: #fee8e8;
  width: 280px;
  height: 293px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  z-index: -1;
}
.Treatments-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.Treatments-heading1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Treatments-heading1 p {
  font-weight: 500;
  font-size: 22px;
  line-height: 35px;
  color: #d521a0;
  font-family: "SF Pro Display", sans-serif;
}
.Treatments-heading2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Treatments-heading2 h1 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 600;
  color: #000000;
  margin-top: -1%;
  font-family: "SF Pro Display", sans-serif;
}
.Treatments-grid-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  flex-wrap: wrap;
  /* background-color: red; */
}
.treatments-container-contained {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.Treatments-grid {
  width: 274px;
  height: 268px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 10px;
  /* margin: 3%; */
}

.Treatments-button {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 2%;
}
.Treatments-button button {
  background-color: #d521a0;
  border-radius: 25px;
  width: 145px;
  height: 51px;
  color: white;
  line-height: 22px;
  font-size: 18px;
  font-weight: 500;
  border: none;
  outline: none;
  font-family: "SF Pro Display", sans-serif;
}
@media screen and (min-width: 643px) and (max-width: 1300px) {
  .Treatments-grid-container {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 60px;
  }
  .Treatments-grid {
    width: 45%;
  }
  .treatment-box-grid {
    width: 40%;
  }
  .treatment-box-grid1 {
    width: 40%;
  }
  .treatment-box-grid2 {
    width: 40%;
  }
  .treatment-box-grid3 {
    width: 40%;
  }
  .img-contained-treatment {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 17%;
  }
  .treat-grid-img-flex {
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .flex-det {
    width: 106% !important;
  }
  .flex-det1 {
    width: 170% !important;
  }
}
@media screen and (min-width: 703px) and (max-width: 749px) {
  .Treatments-grid-container {
    width: 90% !important;
  }
}

@media screen and (max-width: 1170px) {
  .flex-det1 {
    width: 150% !important;
  }
}
@media screen and (max-width: 1056px) {
  .flex-det1 {
    width: 120% !important;
  }
}
@media screen and (max-width: 850px) {
  .flex-det1 {
    width: 100% !important;
  }
}
@media screen and (max-width: 774px) {
  .flex-det {
    width: 90% !important;
  }
}
@media screen and (max-width: 676px) {
  .Treatments-grid-container {
    gap: 30px;
  }
}

@media screen and (max-width: 702px) {
  .Treatments-grid-container {
    display: flex;
    flex-direction: column;
  }
  .Treatments-grid-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 60px;
  }
  .Treatments-grid {
    width: 100%;
  }
  .treatment-box-grid {
    width: 85%;
  }
  .treatment-box-grid1 {
    width: 85%;
  }
  .treatment-box-grid2 {
    width: 85%;
  }
  .treatment-box-grid3 {
    width: 85%;
  }
  .flex-det {
    width: 106% !important;
  }
  .flex-det1 {
    width: 170% !important;
  }
}
@media screen and (max-width: 374px) {
  .flex-det {
    width: 90% !important;
  }
}
@media screen and (max-width: 550px) {
  .flex-det1 {
    width: 150% !important;
  }
}
@media screen and (max-width: 500px) {
  .flex-det1 {
    width: 120% !important;
  }
}
@media screen and (max-width: 412px) {
  .flex-det1 {
    width: 100% !important;
  }
}
@media screen and (max-width: 642px) {
  .treat-grid-img-flex {
    display: flex !important;
    align-items: center !important;
    justify-content: end !important;
  }
}
@media screen and (max-width: 444px) {
  .Treatments-heading2 h1 {
    font-size: 35px;
  }
}
@media screen and (max-width: 383px) {
  .Treatments-heading2 h1 {
    font-size: 30px;
  }
}
@media screen and (max-width: 346px) {
  .Treatments-heading2 h1 {
    font-size: 26px;
  }
}
