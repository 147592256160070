@import url("https://fonts.cdnfonts.com/css/mona-sans");
@import url("https://fonts.cdnfonts.com/css/kollektif");
@import url("https://fonts.cdnfonts.com/css/sf-pro-display");
.leader-container {
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 6%;
  margin-bottom: 2%;
  max-width: 1440px;
}
.leader-container-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.leader-left {
  width: 43%;
  display: flex;
  justify-content: end;
  position: relative;
  /* height: 550px; */
}
.leader-right {
  width: 57%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.leader-left-img {
  width: 100%;
  height: 100%;
  position: relative;
}
.leader-left-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.leader-left-img10 {
  width: 100%;
  height: 100%;
  position: relative;
}
.leader-left-img10 img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.leader-right-image {
  width: 100%;
  height: 100%;
  position: relative;
}
.leader-right-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.leader-heading {
  width: 100%;
  /* background-color: yellow; */
  margin-top: 10%;
}
.leader-heading h2 {
  line-height: 50px;
  font-size: 50px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
  letter-spacing: -2px;
}
.leader-paragraph {
  width: 85%;
  /* background-color: yellow; */
  margin-bottom: 3%;
}
.leader-paragraph p {
  line-height: 25px;
  font-size: 16px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
  word-spacing: -0.7px;
}
.leader-content-container {
  /* background-color: yellow; */
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 86%;
}

.leader-content-container-right {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}
.leader-heading-right {
  width: 100%;
  /* background-color: yellow; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.leader-heading-right h2 {
  font-size: 50px;
  line-height: 52px;
  font-weight: 500;
  font-family: "SF Pro Display", sans-serif;
  color: white;
}
.about-button1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10%;
}
.about-button1 button img {
  margin-bottom: 3%;
}
.about-button1 button {
  width: 162px;
  height: 60px;
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: #d521a0;
  cursor: pointer;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
}
.expert-content3 {
  display: flex;
  width: 100%;
  margin-top: 7%;
  margin-bottom: 2%;
  /* background-color: yellow; */
  /* justify-content: center; */
}
.expert-img3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
}
.expert-img3 img {
  object-fit: cover;
}
.expert-heading-control3 {
  color: white;
  font-family: "SF Pro Display", sans-serif;
  font-size: 20px;
  margin-left: 1%;
  margin-right: 1%;
  font-weight: 500;
}
