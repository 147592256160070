@media screen and (max-width: 1115px) {
  .expert-heading-container1 p {
    font-size: 22px;
    line-height: 32px;
  }
}

@media screen and (max-width: 1070px) {
  .expert-container-contained1 {
    flex-direction: column;
  }
  .expert-left1 {
    width: 100%;
  }
  .expert-right1 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 2%;
  }
  .expert-img1 {
    justify-content: center;
    margin-right: 6%;
  }
  .spannnnn-fit {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  .expert-para-container1 p {
    font-size: 15px;
  }
}
@media screen and (max-width: 1164px) {
  .expert-para-container1 {
    width: 100%;
  }

  .expert-para-container1 p {
    font-size: 14px;
  }
}
/* @media screen and (max-width: 1339px) {
  .expert-heading-container1 p {
    font-size: 30px;
    line-height: 38px;
  }
} */

@media screen and (max-width: 1200px) {
  .expert-heading-container1 p {
    font-size: 25px;
    line-height: 30px;
  }

  .expert-heading-container1 {
    padding-bottom: 0%;
  }
}
@media screen and (max-width: 1164px) {
  /* .expert-heading-container1 p {
    font-size: 22px;
    line-height: 38px;
  } */
}
@media screen and (max-width: 1115px) {
  .expert-heading-container1 p {
    font-size: 22px;
    line-height: 32px;
  }
}
@media screen and (max-width: 409px) {
  .expert-heading-control1 {
    font-size: 16px;
    font-weight: 600;
  }
}
@media screen and (max-width: 470px) {
  .expert-content1 {
    margin-top: 3%;
  }
}
@media screen and (max-width: 372px) {
  .expert-heading-control1 {
    font-size: 17px;
    font-weight: 600;
  }
}
@media screen and (max-width: 356px) {
  .expert-heading-control1 {
    font-size: 16px;
  }
  .expert-content1 {
    margin-top: 5%;
  }
}
@media screen and (max-width: 338px) {
  .expert-heading-control1 {
    font-size: 15px;
  }
}
