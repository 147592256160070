.teeth-container {
  width: 100%;
}
.teeth-container img {
  object-fit: cover;
  width: 100%;
}
.teeth-container-contained {
  width: 90%;
}
.teeth-flex {
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 1441px) {
  .teeth-container-contained {
    width: 100%;
  }
}
