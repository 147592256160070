@import url("https://fonts.cdnfonts.com/css/mona-sans");
@import url("https://fonts.cdnfonts.com/css/kollektif");
@import url("https://fonts.cdnfonts.com/css/sf-pro-display");
.privacy-container {
  width: 100%;
  /* background-color: lime; */
  background-color: #fefcf0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.provacy-content-set {
  /* max-width: 1440px; */
  width: 100%;
}
.provacy-set-max-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.expert-set-fluxxx {
  background-color: #fefcf0;
  /* padding-top: 5%; */
}
.expert-set-fluxxx1 {
  background-color: #fefcf0;
  /* padding-top: 5%; */
  padding-bottom: 20px;
}
.set-max {
  margin-top: -40px;
}
.navbar-navbar {
  background-color: #fefcf0;
}
.privacy-container-contained {
  width: 100%;
  /* background-color: pink; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.privacy-set-flex {
  width: 80%;
  /* background-color: yellow; */
  margin-top: 30px;
}
.privacy-heading {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
}
.privacy-heading h1 {
  font-size: 16px;
  line-height: 25px;
  color: #2d4e68;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
.para-set-privacy {
  width: 100%;
  padding-top: 13px;
  padding-bottom: 3px;
}
.para-set-privacy p {
  font-size: 25px;
  line-height: 25px;
  color: #2d4e68;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.content-bullet-set {
  width: 100%;
  /* background-color: lime; */
  display: flex;
  align-items: center;
  gap: 10px;
  /* margin-bottom: 10%; */
  /* flex-direction: column; */
  /* background-color: lime; */
}

.content-bullet-set p {
  font-size: 16px;
  line-height: 25px;
  color: #2d4e68;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
.para-setmaf-flus {
  width: 100%;
  display: flex;
  align-items: center;
}
.para-setmaf-flus p {
  font-size: 16px;
  line-height: 25px;
  color: #2d4e68;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin: 0;
}
.line-li {
  background-color: #2d4e68;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.line-container {
  display: flex;
  align-items: center;
}
.line-containealpa {
  display: flex;
  align-items: center;
}
.line-containealpa p {
  margin: 0;
}
