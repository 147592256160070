@media screen and (max-width: 1374px) {
  .expert-para-container p {
    letter-spacing: -0.5px;
  }
}
@media screen and (max-width: 1344px) {
  .expert-para-container p {
    letter-spacing: -0.8px;
    margin-top: -1%;
  }
}
@media screen and (max-width: 1409px) {
  .expert-heading-container p {
    /* width: 100%; */
    /* background-color: yellow; */
    font-size: 25px;
  }
}
@media screen and (max-width: 1191px) {
  .expert-heading-container p {
    line-height: 23px;
  }
}
@media screen and (max-width: 1107px) {
  .expert-heading-container p {
    font-size: 18px !important;
  }
}
@media screen and (min-width: 1071px) and (max-width: 1081px) {
  .expert-para-container p {
    font-size: 15px !important;
    line-height: 19px !important;
  }
}
@media screen and (max-width: 1317px) {
  .expert-heading-container p {
    /* width: 100%; */
    /* background-color: yellow; */
    font-size: 22px;
  }
  .expert-para-container p {
    margin-top: -2%;
  }
  .para-setssss p {
    line-height: 20px;
  }
}
@media screen and (max-width: 1244px) {
  .para-setssss1 p {
    line-height: 20px;
  }
}
@media screen and (max-width: 1270px) {
  .expert-right-content-container {
    padding-top: 0%;
  }
}
/* @media screen and (max-width: 1200px) {
  .expert-heading-container p {
    font-size: 25px;
    line-height: 38px;
  }
  .expert-para-container p {
    font-size: 15px;
  }
  .expert-heading-container {
    padding-bottom: 0%;
  }
} */
/* @media screen and (max-width: 1164px) {
  .expert-para-container {
    width: 100%;
  }
  .expert-heading-container p {
    font-size: 22px;
    line-height: 38px;
  }
  .expert-para-container p {
    font-size: 14px;
  }
} */
/* @media screen and (max-width: 1115px) {
  .expert-heading-container p {
    font-size: 22px;
    line-height: 32px;
  }
} */
@media screen and (max-width: 1070px) {
  .expert-container-contained {
    flex-direction: column-reverse;
  }
  .expert-left-contained {
    width: 100%;
    /* background-color: red; */
  }
  .expert-left-contained img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 400px;
    border-radius: 10px;
    /* background-color: red; */
  }
  .expert-left {
    width: 100%;
  }
  .expert-right {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 2%;
  }
  .expert-right-content-container {
    /* background-color: greenyellow; */
    width: 100%;
    margin-left: 0%;
    padding-top: 0%;
  }
  .expert-img {
    justify-content: center;
    margin-right: 6%;
  }
  .expert-container {
    margin-top: 3%;
  }
}
@media screen and (max-width: 1000px) {
  .expert-left-contained img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 350px;
    border-radius: 10px;
    /* background-color: red; */
  }
}
@media screen and (max-width: 600px) {
  .expert-left-contained {
    width: 100%;
  }
  .expert-left-contained img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
