@import url("https://fonts.cdnfonts.com/css/mona-sans");
@import url("https://fonts.cdnfonts.com/css/kollektif");
@import url("https://fonts.cdnfonts.com/css/sf-pro-display");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.process-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.process-main {
  max-width: 1440px;
  /* border: 1px solid yellowgreen; */
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-wrap: wrap; */
  margin-top: 5%;
}
.process-main-dental {
  max-width: 1440px;
  /* border: 1px solid yellowgreen; */
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-wrap: wrap; */
  margin-top: 3%;
  margin-bottom: 2%;
}
.process-main-inside {
  /* width: 1262px; */
  width: 90%;
  height: auto;
  display: flex;
  /* justify-content: space-between; */
  /* align-items: center; */
  /* flex-wrap: wrap; */
}
.process-first-section {
  background-color: #fff7fc;
  /* background-color: pink; */
  /* width: 615px; */
  width: 50%;
  /* display: flex; */
  /* justify-content: center;
  flex-direction: column; */
  /* border: 1px solid purple; */
  /* height: 100%; */
  padding-left: 1.5%;
  border-radius: 10px;
  margin-bottom: 1%;
}
.process-first-section-heading {
  width: 71%;
  /* width: 426px; */
  /* height: 52px; */
  /* padding-top: 1%; */
  /* background-color: yellow; */
  margin-top: 3%;
  margin-left: 1%;
}
.process-first-section-heading h3 {
  font-size: 40px;
  line-height: 60px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
}
.process-first-section-heading-dental {
  width: 80%;
  /* width: 426px; */
  /* height: 52px; */
  /* padding-top: 1%; */
  /* background-color: yellow; */
  margin-top: 3%;
  margin-left: 1%;
}
.process-first-section-heading-dental h3 {
  font-size: 40px;
  line-height: 50px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
}
.process-first-section-heading-dental-pro {
  width: 80%;
  /* width: 426px; */
  /* height: 52px; */
  /* padding-top: 1%; */
  /* background-color: yellow; */
  margin-top: 3%;
  margin-left: 1%;
}
.process-first-section-heading-dental-pro h3 {
  font-size: 40px;
  line-height: 50px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
}
.process-first-section-heading-dental-white {
  width: 80%;
  /* width: 426px; */
  /* height: 52px; */
  /* padding-top: 1%; */
  /* background-color: yellow; */
  margin-top: 3%;
  margin-left: 1%;
}
.process-first-section-heading-dental-white h3 {
  font-size: 40px;
  line-height: 50px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
}
.process-first-section-heading-dental-process {
  width: 80%;
  /* width: 426px; */
  /* height: 52px; */
  /* padding-top: 1%; */
  /* background-color: yellow; */
  margin-top: 3%;
  margin-left: 1%;
}
.process-first-section-heading-dental-process h3 {
  font-size: 40px;
  line-height: 50px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
}
.fa-hmm-heading h2 {
  font-size: 35px !important;
}
/* .process-flex-hmm-ok {
  padding-bottom: 4% !important;
} */
.process-first-section-content {
  width: 97%;
  /* width: 567px; */
  /* height: 280px; */
  padding-top: 3%;
  margin-bottom: 15%;
  margin-left: 2%;
}
.process-first-section-content p {
  font-size: 16px;
  font-family: "SF Pro Display", sans-serif;
  line-height: 26px;
  font-weight: 500;
}

.process-first-section-content-pro {
  width: 97%;
  /* width: 567px; */
  /* height: 280px; */
  padding-top: 3%;
  margin-bottom: 15%;
  margin-left: 2%;
}
.process-first-section-content-pro p {
  font-size: 16px;
  font-family: "SF Pro Display", sans-serif;
  line-height: 26px;
  font-weight: 500;
}
.process-first-sectiion-button {
  width: 100%;
  margin-left: 1.5%;
}
.process-first-sectiion-button button {
  width: 407px;
  height: 60px;
  border-radius: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  line-height: 26px;
  padding-left: 4%;
  font-family: "SF Pro Display", sans-serif;
  outline: none;
  border: none;
  background-color: #fff7fc;
  border: 1px solid black;
}
.process-first-sectiion-button button img {
  object-fit: contain;
  margin-right: 2%;
}

/* Second section */

.process-second-section {
  /* width: 613px; */
  width: 50%;
  /* height: 100%; */
  display: flex;
  justify-content: end;
  align-items: center;
  /* flex-wrap: wrap; */
  /* background-color: yellow; */
}
.process-second-section-block-container {
  width: 100%;
  /* height: auto; */
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-wrap: wrap;

  margin-right: -1%;
}
.process-second-section-block {
  /* width: 49%; */
  /* width: 298px; */
  width: 48%;
  height: 161px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  background-color: #f6a9d4;
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 2%;
}
.process-second-section-block-content-container {
  width: 95%;
  /* width: 298px; */
  /* height: 89.5%; */
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 9%;
  margin-top: 5%;
  /* background-color: red; */
}

.process-second-section-block-heading {
  width: 100%;
  /* height: 30px; */
  /* height: 26.5%; */
  margin-left: 2%;
}
.process-second-section-block-heading h4 {
  font-size: 20px;
  font-family: "SF Pro Display", sans-serif;
  line-height: 30px;
  font-weight: 500;
}
.process-second-section-block-content {
  width: 87%;
  /* width:240px; */
  /* height: 78px; */
  /* background-color: yellow; */
}
.process-second-section-block-content p {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  font-family: "SF Pro Display", sans-serif;
}
.process-second-section-block-content-process {
  width: 87%;
  /* width:240px; */
  /* height: 78px; */
  /* background-color: yellow; */
}
.process-second-section-block-content-process p {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  font-family: "SF Pro Display", sans-serif;
}
.process-second-section-block-content1 {
  width: 95%;
  /* width:240px; */
  /* height: 78px; */
  /* background-color: yellow; */
}
.process-second-section-block-content1 p {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  font-family: "SF Pro Display", sans-serif;
}
.process-second-section-block-content2 {
  width: 95%;
  /* width:240px; */
  /* height: 78px; */
  /* background-color: yellow; */
}
.process-second-section-block-content2 p {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  font-family: "SF Pro Display", sans-serif;
}
