@media screen and (max-width: 1070px) {
  .expert-right-story {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 2%;
  }
  .expert-left-story {
    width: 100% !important;
  }
  .expert-container-contained1-story {
    flex-direction: column;
  }
  .expert-container-story {
    margin-top: 3%;
  }
  .expert-container-story1 {
    margin-top: 3%;
  }
  .expert-heading-container-story {
    width: 100%;
  }
  .expert-para-container-story {
    width: 100%;
  }
  .expert-para-container-story1 {
    width: 100%;
  }

  .about-button10 {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    /* background-color: yellow; */
    margin-bottom: 4%;
  }
  .expert-left-contained-story {
    width: 100%;
    /* background-color: red; */
  }
  .expert-left-contained-story img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 400px;
    border-radius: 10px;
    /* background-color: red; */
  }

  .expert-img-container-contained img {
    object-fit: contain;
    width: 76%;
    height: 94%;
  }
  .expert-img-container-contained1 img {
    object-fit: contain;
    width: 76%;
    height: 94%;
  }
  .story-mg-flex {
    width: 99%;
  }
  .expert-img-container-contained {
    padding-bottom: 1%;
    display: flex;
    align-items: center;
    justify-content: start;
    /* background-color: red; */
  }
  .expert-img-container-contained1 {
    padding-bottom: 1%;
    display: flex;
    align-items: center;
    justify-content: end;
    /* background-color: red; */
  }
}
@media screen and (max-width: 1000px) {
  .expert-left-contained-story img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 350px;
    border-radius: 10px;
  }
  .expert-img-container-contained img {
    object-fit: contain;
    width: 76%;
    height: 94%;
  }
  .expert-img-container-contained1 img {
    object-fit: contain;
    width: 76%;
    height: 94%;
  }
  .story-mg-flex {
    width: 99%;
  }
  .expert-img-container-contained {
    padding-bottom: 1%;
    display: flex;
    align-items: center;
    justify-content: start;
    /* background-color: red; */
  }
  .expert-img-container-contained1 {
    padding-bottom: 1%;
    display: flex;
    align-items: center;
    justify-content: end;
    /* background-color: red; */
  }
}
@media screen and (max-width: 600px) {
  .expert-left-contained-story {
    width: 100%;
  }
  .expert-left-contained-story img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .expert-img-container-contained {
    width: 100%;
    /* height: 100%; */
    /* position: absolute; */
    display: flex;
    padding-bottom: 3.5%;
    box-sizing: border-box;

    /* background-color: yellow; */
  }
  .expert-img-container-contained img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    /* background-color: red; */
  }
  .expert-img-container-contained1 {
    width: 100%;
    /* height: 100%; */
    /* position: absolute; */
    display: flex;
    padding-bottom: 3.5%;
    box-sizing: border-box;

    /* background-color: yellow; */
  }
  .expert-img-container-contained1 img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    /* background-color: red; */
  }
  .expert-left-contained-story {
    width: 100%;
    position: relative;
    display: flex;
    align-items: end;
    box-sizing: border-box;
    /* background-color: red; */
  }
  .expert-left-contained-story img {
    object-fit: cover;
    width: 100%;
  }
  .story-mg-flex {
    width: 94%;
    /* position: absolute; */
    display: flex;
    gap: 20px;
  }
  .story-content-img-flex {
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
  }
}
@media screen and (max-width: 1200px) {
  .expert-para-container1-story p {
    font-size: 15px;
  }
}
@media screen and (max-width: 1164px) {
  .expert-para-container1-story {
    width: 100%;
  }

  .expert-para-container1-story p {
    font-size: 14px;
  }
}
@media screen and (max-width: 1200px) {
  .expert-heading-container-story {
    padding-bottom: 0%;
  }
  .expert-heading-container-story p {
    font-size: 30px;
    line-height: 40px;
  }
}
@media screen and (max-width: 1100px) {
  .expert-heading-container-story {
    padding-bottom: 0%;
  }
  .expert-heading-container-story p {
    font-size: 28px;
    line-height: 35px;
  }
}
@media screen and (max-width: 1140px) {
  .expert-para-container-story p {
    font-size: 15px;
  }
  .expert-para-container-story1 p {
    font-size: 15px;
  }
}
@media screen and (max-width: 1270px) {
  .expert-right-story {
    margin-bottom: 0%;
  }
}
@media screen and (max-width: 357px) {
  .expert-heading-container-story p {
    font-size: 25px;
    line-height: 32px;
  }
}
@media screen and (max-width: 322px) {
  .expert-heading-container-story p {
    font-size: 24px;
  }
}
