@media screen and (max-width: 1430px) {
  .lusia-heading-again h2 {
    font-size: 38px;
  }
}
@media screen and (max-width: 1359px) {
  .lusia-heading-again h2 {
    font-size: 36px;
  }
}
@media screen and (max-width: 1287px) {
  .lusia-heading-again h2 {
    font-size: 34px;
  }
}
@media screen and (max-width: 1216px) {
  .lusia-heading-again h2 {
    font-size: 32px;
  }
}
@media screen and (max-width: 1144px) {
  .lusia-heading-again h2 {
    font-size: 30px;
  }
}
@media screen and (max-width: 1073px) {
  .lusia-heading-again h2 {
    font-size: 28px;
  }
}
@media screen and (max-width: 1001px) {
  .lusia-heading-again h2 {
    font-size: 27px;
  }
}
@media screen and (max-width: 1245px) {
  .lusia-para {
    width: 100%;
  }
  .lusia-para p {
    font-weight: 500;
  }
}
@media screen and (max-width: 1213px) {
  .lusia-conatiner {
    margin-top: 0%;
  }
}
@media screen and (max-width: 1170px) {
  .lusia-conatiner {
    margin-top: -2%;
  }
}
@media screen and (max-width: 1050px) {
  .lusia-conatiner {
    margin-top: -3%;
  }
}
@media screen and (max-width: 1300px) {
  .lusia-left {
    align-self: flex-start;
    /* margin-top: 3%; */
  }
}
@media screen and (max-width: 1150px) {
  .right-para p {
    font-size: 15px;
    line-height: 22px;
  }
  .lusia-conatiner {
    height: 300px;
    background-color: white;
  }
}
@media screen and (max-width: 1041px) {
  .lusia-conatiner {
    /* height: 35vh; */
    height: 300px;
    background-color: white;
    /* margin-top: -2%; */
  }
  .lusia-right-contained {
    margin-top: 9%;
  }
}
@media screen and (max-width: 991px) {
  .lusia-conatiner {
    margin-top: 0%;
  }
  .lusia-right-contained {
    margin-top: 12%;
  }
  .lusia-left {
    width: 50%;
  }
  .lusia-right {
    width: 50%;
  }
  .lusia-contained {
    width: 82%;
  }

  .lusia-para p {
    font-size: 17px;
  }
  .lusia-conatiner {
    height: 300px;
    margin-bottom: 1%;
  }
}
@media screen and (max-width: 814px) {
  .lusia-conatiner {
    margin-top: 3%;
  }
}
@media screen and (max-width: 791px) {
  .lusia-right-contained {
    margin-top: 16%;
  }
}
@media screen and (max-width: 766px) {
  .lusia-conatiner {
    margin-top: 6%;
  }
}
@media screen and (max-width: 693px) {
  .lusia-conatiner {
    margin-top: 8%;
  }
}
@media screen and (max-width: 746px) {
  .lusia-heading-again h2 {
    font-size: 25px;
  }
}
@media screen and (max-width: 691px) {
  .lusia-heading-again h2 {
    font-size: 23px;
  }
}
@media screen and (max-width: 635px) {
  .lusia-heading-again h2 {
    font-size: 21px;
  }
}
@media screen and (max-width: 850px) {
  .right-para p {
    font-size: 15px;
    line-height: 18px;
    margin-top: -2%;
  }
}
@media screen and (max-width: 700px) {
  .right-para p {
    font-size: 14px;
    line-height: 16px;
  }
}
@media screen and (max-width: 850px) {
  .lusia-container-full-contained {
    margin-top: -10%;
    /* background-color: red; */
  }
}
@media screen and (max-width: 700px) {
  .lusia-container-full-contained {
    margin-top: -15%;
    /* background-color: red; */
  }
}
@media screen and (max-width: 693px) {
  .lusia-container-full-contained {
    margin-top: -20%;
    /* background-color: red; */
  }
}
@media screen and (max-width: 600px) {
  .lusia-container-full-contained {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* background-color: red; */
    /* margin-bottom: 0%; */
  }
  .bbbbbbb {
    display: none;
  }
  .lusia-conatiner {
    /* background-color: yellow; */
    margin-top: 6%;
    margin-bottom: 5%;
    height: auto;
  }

  .lusia-left {
    width: 100%;
    /* background-color: yellow; */
  }
  .lusia-right {
    width: 100%;
  }
  .lusia-img {
    /* width: 50%; */
    display: flex;
    align-items: center;
    /* background-color: red; */
    justify-content: center;
  }
  .lusia-content {
    display: flex;
    width: 35%;
    margin-left: 32%;
  }
  .lusia-container-full-contained {
    /* background-color: red; */
    margin-top: -10%;
  }
  .lusia-para {
    margin-top: 1%;
  }
  .lusia-para p {
    line-height: 20px;
  }
  .lusia-right {
    align-items: center;
  }
  .lusia-right-contained {
    width: 95%;
    /* background-color: red; */
    margin-top: 8%;
  }
}

@media screen and (max-width: 516px) {
  .lusia-content {
    width: 40%;
    /* background-color: red; */
    margin-left: 29%;
  }
}

@media screen and (max-width: 451px) {
  .lusia-content {
    width: 50%;
    /* background-color: red; */
    margin-left: 24%;
  }
}

@media screen and (max-width: 369px) {
  .lusia-container-full-contained {
    /* background-color: red; */
    margin-top: 1%;
  }
}
@media screen and (max-width: 540px) {
  .lusia-conatiner {
    height: 200px;
    margin-bottom: 1%;
  }
}
@media screen and (max-width: 490px) {
  .lusia-conatiner {
    margin-top: 21% !important;
    /* background-color: red; */
  }
}
@media screen and (max-width: 450px) {
  .lusia-conatiner {
    margin-top: 25% !important;
    /* background-color: red; */
  }
}
@media screen and (max-width: 390px) {
  .lusia-conatiner {
    margin-top: 28% !important;
    /* background-color: red; */
  }
}
@media screen and (max-width: 540px) {
  .lusia-conatiner {
    margin-top: 18%;
  }
}
@media screen and (max-width: 350px) {
  .lusia-conatiner {
    /* background-color: red; */
    /* height: 30vh; */
    margin-top: 30% !important;
  }
}
@media screen and (max-width: 361px) {
  .lusia-content {
    width: 55%;
    /* background-color: red; */
    margin-left: 22%;
  }
}
@media screen and (max-width: 328px) {
  .lusia-content {
    width: 60%;
    margin-left: 16%;
  }
}
@media screen and (max-width: 370px) {
  .lusia-right-contained {
    margin-top: 8%;
  }
}
@media screen and (max-width: 323px) {
  .lusia-conatiner {
    /* background-color: red; */
    margin-top: 35% !important;
  }
}
@media screen and (max-width: 380px) {
  .lusia-conatiner {
    height: 150px;
    background-color: white;
  }
}
@media screen and (max-width: 471px) {
  .lusia-conatiner {
    height: 160px;
    background-color: white;
  }
}
@media screen and (max-width: 357px) {
  .lusia-conatiner {
    margin-bottom: -10%;
  }
}
@media screen and (max-width: 323px) {
  .lusia-conatiner {
    margin-bottom: -6%;
  }
}
