@media only screen and (max-width: 1023px) {
  .slider-wrapper .slide-button {
    display: none !important;
  }

  .slider-wrapper .image-list {
    gap: 10px;
    margin-bottom: 15px;
    scroll-snap-type: x mandatory;
  }

  .slider-wrapper .image-list .image-item {
    width: 280px;
    height: 380px;
  }

  .slider-scrollbar .scrollbar-thumb {
    width: 7%;
  }
  .container-contained-wrapper {
    width: 100%;
    /* background-color: yellow; */
    margin-left: 0%;
  }
  .container15 .slider-scrollbar {
    margin-left: 8%;
  }
}
@media only screen and (max-width: 800px) {
  .slider-scrollbar .scrollbar-thumb {
    width: 7%;
  }
}
@media only screen and (max-width: 700px) {
  .slider-scrollbar .scrollbar-thumb {
    width: 8%;
  }
}
@media only screen and (max-width: 600px) {
  .slider-scrollbar .scrollbar-thumb {
    width: 9%;
  }
}
@media only screen and (max-width: 500px) {
  .slider-scrollbar .scrollbar-thumb {
    width: 10%;
  }
}
@media only screen and (max-width: 400px) {
  .slider-scrollbar .scrollbar-thumb {
    width: 11%;
  }
}
@media only screen and (max-width: 350px) {
  .slider-scrollbar .scrollbar-thumb {
    width: 12%;
  }
}
@media only screen and (max-width: 600px) {
  .slider-contained {
    text-align: center;
  }
}
@media only screen and (max-width: 558px) {
  .slider-para p {
    font-size: 35px;
  }
}
@media only screen and (max-width: 488px) {
  .slider-para p {
    font-size: 30px;
  }
}
@media only screen and (max-width: 418px) {
  .slider-para p {
    font-size: 25px;
  }
}
@media only screen and (max-width: 348px) {
  .slider-para p {
    font-size: 22px;
  }
}

@media only screen and (min-width: 1500px) {
  .container-contained-wrapper {
    margin-left: 0%;
  }
  .slider-conatineer-contained {
    width: 100%;
  }
}
