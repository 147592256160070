@media screen and (max-width: 1270px) {
  .church-cpntainer-contained-material {
    width: 95%;
  }
  .church-main-heading {
    width: 95%;
  }
  .church-content-control {
    padding-bottom: 22px !important;
  }
}
@media screen and (max-width: 1200px) {
  .church-content-control {
    padding-bottom: 52px !important;
  }
}
@media screen and (max-width: 1120px) {
  .church-img {
    width: 40%;
  }
  .church-heading-container {
    width: 60%;
  }
}
@media screen and (max-width: 1093px) {
  .church-img {
    width: 35%;
  }
  .church-heading-container {
    width: 65%;
  }
}
@media screen and (max-width: 1010px) {
  .church-img img {
    margin-left: -22%;
  }
  .church-img {
    width: 30%;
  }
  .church-heading-container {
    width: 70%;
  }
}
@media screen and (max-width: 937px) {
  .img-heading-container h1 {
    font-size: 14px;
  }
}
@media screen and (max-width: 878px) {
  .img-heading-container h1 {
    font-size: 13px;
  }
  .church-img img {
    margin-left: -25%;
  }
}
@media screen and (max-width: 870px) {
  .church-container {
    display: flex;
    justify-content: center;
    justify-content: center;
    /* background-color: yellow; */
  }
  .church-main-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .church-cpntainer-contained-material {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    width: 90%;
    gap: 40px;
    /* background-color: orange; */
  }
  .church-left {
    width: 100%;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    justify-content: center;
    margin-bottom: 5%;
  }
  .church-right {
    width: 100%;
    /* background-color: yellow; */
    display: flex;

    justify-content: center;
    justify-content: center;
  }
  .church-img img {
    margin-left: 0%;
  }
  .church-img {
    width: 30%;
  }
  .church-heading-container {
    width: 70%;
  }
}
@media screen and (max-width: 690px) {
  .church-img {
    width: 40%;
  }
  .church-heading-container {
    width: 60%;
  }
}
@media screen and (max-width: 496px) {
  .church-left {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .church-img {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .church-left-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 10px;
    /* background-color: orange; */
  }
  .church-img {
    width: 100%;
  }
  .church-heading-container {
    width: 100%;
  }
  .church-heading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .img-heading-container {
    text-align: center;
  }
}
@media screen and (max-width: 638px) {
  .church-main-heading h2 {
    font-size: 30px;
  }
}
@media screen and (max-width: 490px) {
  .church-main-heading h2 {
    font-size: 25px;
  }
}
@media screen and (max-width: 490px) {
  .church-main-heading h2 {
    font-size: 25px;
  }
}
@media screen and (max-width: 1341px) {
  .church-content-control {
    padding-bottom: 52px;
  }
}
@media screen and (min-width: 1271px) and (max-width: 1374px) {
  .church-heading-container-uffff {
    padding-bottom: 30px;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1230px) {
  .church-heading-container-uffff {
    padding-bottom: 30px;
  }
}
@media screen and (min-width: 871px) and (max-width: 1200px) {
  .church-heading-container-uffff {
    padding-bottom: 30px;
  }
}
