@import url("https://fonts.cdnfonts.com/css/mona-sans");
@import url("https://fonts.cdnfonts.com/css/kollektif");
@import url("https://fonts.cdnfonts.com/css/sf-pro-display");
.expert-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  /* margin-bottom: 3%; */
  /* background-color: red; */
}
.expert-content-container-full-responsive {
  display: flex;
  align-items: center;
  justify-content: center;
}
.expert-content-container-full {
  max-width: 1440px;
  width: 100%;
}
.expert-container-contained {
  /* background-color: pink; */
  display: flex;
  width: 88%;
}
.expert-left {
  width: 50%;
  /* background-color: yellow; */
}
.expert-left-contained {
  width: 100%;
}
.expert-left-contained img {
  object-fit: cover;
  width: 100%;
}
.expert-right-content-container {
  /* background-color: greenyellow; */
  width: 85%;
  margin-left: 5%;
  padding-top: 2%;
}
.expert-right {
  width: 50%;
  /* background-color: orange; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.expert-content {
  display: flex;
  width: 100%;
  /* background-color: yellow; */
}
.expert-img {
  width: 100%;
  display: flex;
  align-items: center;
  /* background-color: red; */
}
.expert-img img {
  object-fit: cover;
}
.expert-heading {
  width: 80%;
  /* background-color: red; */
}
.expert-heading-control {
  color: #d521a0;
  font-family: "SF Pro Display", sans-serif;
  font-size: 20px;
  margin-left: 1%;
  font-weight: 500;
}
.expert-heading-container {
  width: 100%;
  /* background-color: green; */
  padding-bottom: 3%;
}
.expert-heading-container p {
  font-family: "SF Pro Display", sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 30px;
}
.expert-para-container {
  width: 96.5%;
  /* width: 100%; */
}
.expert-para-container p {
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
}

.about-heading-invis-dental-para {
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  color: #d521a0;
  width: 100%;
  padding-top: 5px;
  /* white-space: nowrap; */
}

.set-flex-flux {
  /* background-color: orange; */
  margin-bottom: 10px;
}
.flex-title-set-flex {
  /* width: 100%; */
  display: flex;
  align-items: center;

  gap: 10px;
  height: 50px;
  /* background-color: red; */
  /* background-color: orange; */
}
@media only screen and (max-width: 605px) {
  .img-line-container-flex {
    /* background-color: lime; */
  }
  .about-heading-invis-dental-para {
    /* background-color: lightblue; */
    display: flex;
    justify-content: start !important;
    text-align: left;
  }
  .flex-title-set-flex {
    /* background-color: yellow; */
  }
  .set-flex-flux {
    /* background-color: lime; */
    display: flex;
    justify-content: center;
  }
}
@media only screen and (min-width: 758px) and (max-width: 917px) {
  .about-heading-invis-dental-para {
    white-space: nowrap;
    font-size: 15px;
  }
}
@media only screen and (min-width: 606px) and (max-width: 734px) {
  .about-heading-invis-dental-para {
    white-space: nowrap;
    font-size: 15px;
  }
}
@media only screen and (max-width: 367px) {
  .about-heading-invis-dental-para {
    white-space: nowrap;
  }
}
@media only screen and (max-width: 340px) {
  .about-heading-invis-dental-para {
    font-size: 15px;
  }
}
