@import url("https://fonts.cdnfonts.com/css/mona-sans");
@import url("https://fonts.cdnfonts.com/css/kollektif");
@import url("https://fonts.cdnfonts.com/css/sf-pro-display");
.nav-link {
  color: #333333 !important;
  font-family: "SF Pro Display", sans-serif !important;
  font-weight: 500 !important;
  width: 125px;
  height: 43px;
  border-radius: 30px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.treat-move {
  color: #333333 !important;
  font-family: "SF Pro Display", sans-serif !important;
  font-weight: 500 !important;
  text-decoration: none !important;
  /* background-color: yellow !important; */
}

.nav-link.active {
  background-color: #d521a0 !important;
  color: white !important;
  font-weight: 600 !important;
  width: 125px;
  height: 43px;
  text-align: center;
  border-radius: 30px;
  padding: 10px 28px 12px 28px;
  gap: 10px;
}
.nav-item {
  font-size: 16px !important;
  /* background-color: yellow; */
}
.dropdown-toggle::after {
  display: none !important;
  /* background-color: lightseagreen; */
}
.navbar-button button {
  background-color: #d521a0 !important;
  color: white !important;
  font-weight: 500;
  border-radius: 30px;
  padding: 10px 28px 12px 28px;
  gap: 10px;
  font-size: 18px !important;
  border: none;
  outline: none;
}
#get-app {
  background-color: #d521a0 !important;
  color: white !important;
  border-radius: 30px;
  padding: 10px 28px 12px 28px;
  gap: 10px;
  font-size: 18px !important;
  /* background-color: lime !important; */
}
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.navbar-toggler {
  border: none !important;
}

.container-fluid {
  gap: 10vw;
  /* background-color: orange !important; */
}
.container10 {
  width: 88%;
  /* background-color: red; */
}
.navbar-contained {
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  background-color: white;
  z-index: 20;
}
.navbar-contained1 {
  width: 100%;
  display: flex;
  justify-content: center;
  /* background-color: rgba(231, 235, 236, 0.288); */
  margin-bottom: 2%;
  position: fixed;
  top: 0;
  background-color: white;
  z-index: 20;
  /* background-color: purple; */
}

.dropdown-item.active {
  background-color: white !important;
  color: black !important;
}
.navbar-nav .dropdown-menu .dropdown-item:focus,
.navbar-nav .dropdown-menu .dropdown-item:hover {
  background-color: #d521a0;
  color: white;
}
.navbar-nav {
  display: flex;
  align-items: center;
  /* background-color: purple; */
}
.dropdown-menu {
  border: none !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  border-radius: 16px !important;
}
.nav-control-ul {
  /* background-color: red; */
}
