@media only screen and (max-width: 800px) {
  .expert-set-fluxxx1 h2 {
    font-size: 40px;
  }
}
@media only screen and (max-width: 778px) {
  .line-container {
    margin-bottom: 22px;
  }
}
@media only screen and (min-width: 779px) and (max-width: 954px) {
  .responsd-para-set {
    margin-bottom: 22px;
  }
}
@media only screen and (min-width: 405px) and (max-width: 522px) {
  .responsd-para-set {
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 345px) and (max-width: 358px) {
  .responsd-para-set {
    margin-bottom: 50px !important;
  }
}
@media only screen and (max-width: 404px) {
  .line-container {
    margin-bottom: 48px;
  }
}
@media only screen and (max-width: 358px) {
  .line-setting {
    margin-bottom: 72px;
  }
}

@media only screen and (max-width: 500px) {
  .expert-set-fluxxx1 h2 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 380px) {
  .expert-set-fluxxx1 h2 {
    font-size: 25px;
  }
}
@media only screen and (max-width: 897px) {
  .para-set-privacy p {
    line-height: 30px;
  }
}
@media only screen and (max-width: 600px) {
  .expert-set-fluxxx1 {
    padding-bottom: 0px;
  }
}

@media only screen and (max-width: 1326px) {
  .line-containealpa {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 684px) {
  .line-containealpa {
    margin-bottom: 45px;
  }
}
@media only screen and (max-width: 543px) {
  .line-containealpa {
    margin-bottom: 42px;
  }
}
@media only screen and (max-width: 373px) {
  .line-containealpa {
    margin-bottom: 70px;
  }
}
@media only screen and (max-width: 491px) {
  .line-a-set {
    margin-bottom: 75px;
  }
}
@media only screen and (max-width: 385px) {
  .line-a-set p {
    /* background-color: red; */
    margin-bottom: 19px !important;
  }
}
