@import url("https://fonts.cdnfonts.com/css/mona-sans");
@import url("https://fonts.cdnfonts.com/css/kollektif");
@import url("https://fonts.cdnfonts.com/css/sf-pro-display");
.touch-para-container {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.trsygrup-send {
  margin-top: 140px;
  /* background-color: red; */
}
.touch-para-container p {
  font-size: 22px;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 8%;
}
.flex-touch {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.left-touch-setttt {
  width: 100% !important;
}
.dental-touch {
  position: relative;
  z-index: -1;
}
.contact-touch {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20%;
  background-color: transparent !important;
}
.touch-flex-conent {
  margin-top: -40%;
}
.flex-heading-touch {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
}
.flex-para-touch {
  width: 60%;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
}
.touch-button-flex {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
}
.form-flex-touch {
  width: 90%;
}
.flex-form-content-set {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center;
}
.dr-control {
  margin-bottom: 50px;
}
@media only screen and (max-width: 1253px) {
  .flex-touch-hmm-set {
    display: flex !important;
    flex-direction: column !important;
  }
  .settttt-label {
    margin-top: -3%;
  }
}
.flex-item-touch textarea {
  padding-left: 2%;
}
.flex-flex {
  width: 92% !important;
}
@media only screen and (max-width: 900px) {
  .flex-flex {
    width: 89% !important;
  }
}
@media only screen and (max-width: 862px) {
  .touch-para-container {
    width: 90%;
  }
}
@media only screen and (max-width: 391px) {
  .touch-para-container p {
    font-size: 18px;
  }
}
@media only screen and (max-width: 769px) {
  .flex-para-touch {
    width: 90%;
  }
}
@media only screen and (max-width: 456px) {
  .flex-para-touch {
    width: 95%;
  }
}
@media only screen and (max-width: 430px) {
  .flex-para-touch p {
    font-size: 15px !important;
  }
}
@media only screen and (max-width: 324px) {
  .hmm-flex-head h2 {
    font-size: 30px !important;
  }
}
