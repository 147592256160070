@import url("https://fonts.cdnfonts.com/css/mona-sans");
@import url("https://fonts.cdnfonts.com/css/kollektif");
@import url("https://fonts.cdnfonts.com/css/sf-pro-display");
.lusia-conatiner {
  width: 100vw;

  height: auto;
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
  margin-top: 2%;
  margin-bottom: 2%;
}

.lusia-container-full-contained {
  max-width: 1440px;
  width: 100%;
  display: flex;
  align-items: center;
  /* background-color: yellow; */
}

.lusia-contained {
  width: 72%;
  /* background-color: yellow; */
  padding-top: 5%;
}
.lusia-content {
  display: flex;
  width: 100%;
  /* background-color: yellow; */
}
.lusia-heading {
  width: 80%;
  /* background-color: red; */
}
.lusia-heading-control {
  color: #d521a0;
  font-family: "SF Pro Display", sans-serif;
  font-size: 18px;
  margin-left: 1%;
  font-weight: 600;
}
.lusia-heading-again {
  padding-top: 2%;
  width: 100%;
  /* background-color: red; */
}
.lusia-heading-again h2 {
  font-family: "SF Pro Display", sans-serif;
  font-size: 40px;
  font-weight: 700;
}
.left-button1 {
  width: 100%;
  /* margin-bottom: 3%; */
  padding-top: 1%;
}
.left-button1 button {
  width: 153px;
  height: 51px;
  border-radius: 25px;
  border: none;
  outline: none;
  background-color: #d521a0;
  cursor: pointer;
  font-family: "SF Pro Display", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}
.lusia-para {
  width: 80%;
  padding-top: 2%;
  margin-right: 5%;
}
.lusia-para p {
  font-family: "SF Pro Display", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  /* background-color: yellow; */
}
.lusia-img {
  /* width: 100%; */
  display: flex;
  align-items: center;
  /* background-color: red; */
  justify-content: center;
}
.lusia-img img {
  object-fit: cover;
}
.lusia-left {
  width: 44%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.lusia-right {
  width: 56%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.right-para {
  width: 100%;
  /* background-color: red; */
  padding: 2px;
}
.lusia-right-contained {
  width: 95%;
  /* background-color: red; */
  margin-top: 5%;
}
.right-para p {
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  /* background-color: red; */
}
